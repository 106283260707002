import QueuePlayNextIcon from "@mui/icons-material/QueuePlayNext";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  NavDropdown
} from "react-bootstrap";
import { useExportToSpmSvnHook } from "../../backend/hooks/importExportMenu/mutationExportToSpmSvn";
import { encryptStringWithRsaPublicKey } from "../../helpers/stringHelper";
import FileIcon from "../../components/abstractComponents/fileIcon";
import { useGetSvnContentQuery } from "../../backend/hooks/importExportMenu/queryGetSvnContent";

import Error from "../abstractComponents/error";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { SpmSvnFile } from "../../backend/types";

const MAXIMUM_CLEAR_PASSWORD = 50;

// Component that renders everything related to SpmSvn Export
export default function ExportToSpmSvn(props: {
  currentFolder: string;
  disabled: boolean;
  activityId: number;
  refetchRepoObjects: Function;
  selectedRows: any[];
  repository: any;
}) {
  const defaultRootAddressSpmSvnExport =
    localStorage.getItem("defaultRootAddressSpmSvnExport") &&
    localStorage.getItem("defaultRootAddressSpmSvnExport") !== "undefined" &&
    localStorage.getItem("defaultRootAddressSpmSvnExport") !== ""
      ? String(localStorage.getItem("defaultRootAddressSpmSvnExport"))
      : "<EntryName> / <RelativePath>";
  const [rootAddress, setRootAddress] = useState(
    defaultRootAddressSpmSvnExport
  );
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [description, setDescription] = useState("");
  const [spmSvnUsername, setSpmSvnUsername] = useState(
    localStorage.getItem("spmSvnUsername") || ""
  );
  const [spmSvnPassword, setSpmSvnPassword] = useState(
    localStorage.getItem("spmSvnPassword") || ""
  );
  const [thereIsAnOverwrite, setThereIsAnOverwrite] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any>([]);

  const { getSvnContent, error, loading, data } = useGetSvnContentQuery();

  useEffect(() => {
    const rows = props.selectedRows.map((name: string) => {
      let willOverwrite = false;

      data?.getSpmSvnContent.forEach((entry: SpmSvnFile) => {
        if (
          (name.endsWith("/") &&
            entry.name.replace("/", "") === name.split("/").at(-2)) ||
          (!name.endsWith("/") && entry.name === name.split("/").at(-1))
        ) {
          setThereIsAnOverwrite(true);
          willOverwrite = true;
        }
      });

      return { name: name, willOverwrite: willOverwrite };
    });

    setSelectedRows(rows);
  }, [props.selectedRows, data]);

  useEffect(() => {
    if (spmSvnPassword && rootAddress && spmSvnPassword) {
      let password;
      if (
        spmSvnPassword.length < MAXIMUM_CLEAR_PASSWORD &&
        !spmSvnPassword.endsWith("=")
      ) {
        password = encryptStringWithRsaPublicKey(spmSvnPassword);
        setSpmSvnPassword(password);
        localStorage.setItem("spmSvnUsername", spmSvnUsername);
        localStorage.setItem("spmSvnPassword", password);
      } else {
        password = spmSvnPassword;
      }

      getSvnContent({
        variables: {
          svnUser: spmSvnUsername,
          encryptedPassword: password,
          absolutePath: rootAddress
        }
      });
    }
  }, [rootAddress]);

  const {
    exportToSpmSvn,
    spmSvnData,
    loadingSpmSvn,
    errorSpmSvn,
    resetSpmSvnMutation
  } = useExportToSpmSvnHook();

  const handleExportToSpmSvn = (password?: string) => {
    if (
      !thereIsAnOverwrite ||
      (thereIsAnOverwrite &&
        window.confirm("Are you sure you want to overwrite files on SVN?"))
    ) {
      exportToSpmSvn({
        variables: {
          spmSvnUsername,
          encryptedPassword: password ? password : spmSvnPassword,
          targetFolderPath: rootAddress,
          absolutePaths: props.selectedRows,
          description,
          activityId: props.activityId,
          repoId: props.repository.id
        }
      });
    }
  };

  useEffect(() => {
    setDescription("");
    //animation is wrong, so we delay
    setTimeout(resetSpmSvnMutation, 500);
  }, [isExportModalVisible]);

  const expotToSpmSvn = () => {
    localStorage.setItem("defaultRootAddressSpmSvnExport", rootAddress);
    if (
      spmSvnPassword.length < MAXIMUM_CLEAR_PASSWORD &&
      !spmSvnPassword.endsWith("=")
    ) {
      //Password is not encrypted, so we encrypt it
      const newPassword = encryptStringWithRsaPublicKey(spmSvnPassword);
      setSpmSvnPassword(newPassword);
      localStorage.setItem("spmSvnUsername", spmSvnUsername);
      localStorage.setItem("spmSvnPassword", newPassword);
      handleExportToSpmSvn(newPassword);
    } else {
      handleExportToSpmSvn();
    }
  };

  return (
    <>
      <NavDropdown.Item
        size="small"
        onClick={() => {
          setIsExportModalVisible(true);
        }}
        disabled={props.disabled}
        id="spmSvnExportItem"
      >
        <QueuePlayNextIcon /> SPM SVN Export
      </NavDropdown.Item>

      <Modal
        show={isExportModalVisible}
        onHide={() => setIsExportModalVisible(false)}
        dialogClassName="modal-80w"
        enforceFocus={false}
        aria-labelledby="spmSvnExportTitle"
      >
        <Modal.Header closeButton>
          <Modal.Title id="spmSvnExportTitle">Export to SPM SVN</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            {(loadingSpmSvn || loading) && (
              <Row>
                <div className="center_div">
                  <Spinner animation="border" className="spinner_color" />
                  <p>Loading...</p>
                </div>
              </Row>
            )}
            {spmSvnData ? (
              <div className="center_div">
                <CheckCircleOutlineIcon color="success" fontSize="large" />
                <p>Data export has started!</p>
              </div>
            ) : (
              <>
                <Row
                  className={
                    spmSvnPassword.length > MAXIMUM_CLEAR_PASSWORD
                      ? "login_grey_color_row"
                      : ""
                  }
                >
                  <Col xs={2}>
                    <Form.Label>SPM SVN Username:</Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Control
                      type="text"
                      required
                      id="spmSvnExportUsernameFormControl"
                      placeholder="Enter username"
                      value={spmSvnUsername}
                      onChange={(e) => setSpmSvnUsername(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row
                  className={
                    spmSvnPassword.length > MAXIMUM_CLEAR_PASSWORD
                      ? "login_grey_color_row"
                      : ""
                  }
                >
                  <Col xs={2}>
                    <Form.Label>SPM SVN Password:</Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Control
                      type="password"
                      required
                      id="spmSvnExportPasswordFormControl"
                      placeholder="Enter password"
                      value={spmSvnPassword}
                      onChange={(e) => setSpmSvnPassword(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={2}>
                    <Form.Label>
                      SPM SVN path:<span className="red_form">*</span>
                    </Form.Label>
                  </Col>
                  <Col xs={8}>
                    <Form.Control
                      type="text"
                      required
                      id="spmSvnExportRootAddressFormControl"
                      placeholder="Enter SPM SVN path"
                      value={rootAddress}
                      onChange={(e) => setRootAddress(e.target.value)}
                    />
                  </Col>
                </Row>
                <br />
                <br />
                <Row>
                  <Col>
                    {selectedRows.map((entry: any) => {
                      return (
                        <div
                          data-testid="spmSvnExportFileOutputElement"
                          key={entry.name}
                          className={
                            entry.willOverwrite
                              ? "drop_comment_file drop_comment_file_warning"
                              : "drop_comment_file"
                          }
                        >
                          <FileIcon
                            fileName={entry.name}
                            isValid={false}
                            fontSize={"small"}
                          />{" "}
                          {props.repository.name + "/" + entry.name}
                          {entry.willOverwrite && " (will overwrite)"}
                        </div>
                      );
                    })}
                  </Col>
                  <Col>
                    <Row>
                      <Col xs={4}>
                        <Form.Label>
                          Export Description:<span className="red_form">*</span>
                        </Form.Label>
                      </Col>
                      <Col xs={6}>
                        <Form.Control
                          type="text"
                          as="textarea"
                          required
                          rows={3}
                          id="spmSvnExportDescriptionFormControl"
                          placeholder="Enter description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
            <br />
            <br />
            <Row>
              <Col xs={11}>
                {!spmSvnData && (
                  <Button
                    variant="secondary"
                    onClick={() => setIsExportModalVisible(false)}
                    disabled={loadingSpmSvn}
                  >
                    Cancel
                  </Button>
                )}
              </Col>
              <Col xs={1}>
                {spmSvnData ? (
                  <Button
                    variant="secondary"
                    onClick={() => setIsExportModalVisible(false)}
                    disabled={loadingSpmSvn}
                    id="closeSpmSvnExportButton"
                  >
                    Close
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    id="finishSpmSvnExportButton"
                    disabled={
                      !rootAddress ||
                      !spmSvnUsername ||
                      !spmSvnPassword ||
                      loadingSpmSvn ||
                      !description
                    }
                    onClick={expotToSpmSvn}
                  >
                    Export
                  </Button>
                )}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      {errorSpmSvn || (error && <Error error={errorSpmSvn || error} />)}
    </>
  );
}
