import { gql, useQuery } from "@apollo/client";
export const GET_MY_PMX_ACTIVITIES_QUERY = gql`
  {
    getMyPmxActivities {
      __typename
      id
      trialNumber
      trialShortDescription
      bayNumber
      projectId
      mainRepository {
        name
        id
        isStandard
        created
      }
    }
  }
`;

export function useGetMyPmxActivitiesQuery(skip: boolean) {
  const {
    data: activities,
    loading: activitesLoading,
    error,
    refetch: refetchActivites
  } = useQuery(GET_MY_PMX_ACTIVITIES_QUERY, {
    skip: skip,
    notifyOnNetworkStatusChange: true
  });

  return {
    activities,
    refetchActivites,
    activitesLoading,
    error
  };
}
