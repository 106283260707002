import { gql, useLazyQuery } from "@apollo/client";
export const QUERY_GET_MODSPACE_CONTENT = gql`
  query getModspaceContent(
    $modspaceUser: String!
    $encryptedPassword: String!
    $absolutePath: String!
  ) {
    getModspaceContent(
      input: {
        modspaceUser: $modspaceUser
        encryptedPassword: $encryptedPassword
        absolutePath: $absolutePath
      }
    ) {
      name
      size
    }
  }
`;

export function useGetModspaceContentQuery() {
  const [getModspaceContent, { error, loading, data }] = useLazyQuery(
    QUERY_GET_MODSPACE_CONTENT,
    { fetchPolicy: "no-cache" }
  );

  return {
    getModspaceContent,
    error,
    loading,
    data
  };
}
