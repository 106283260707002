import { gql, useMutation } from "@apollo/client";

export const MUTATION_EXPORT_TO_SPM_SVN = gql`
  mutation exportToSpmSvn(
    $spmSvnUsername: String!
    $encryptedPassword: String!
    $targetFolderPath: String!
    $absolutePaths: [String!]!
    $description: String
    $activityId: ID!
    $repoId: ID!
  ) {
    exportToSpmSvn(
      input: {
        spmSvnUsername: $spmSvnUsername
        encryptedPassword: $encryptedPassword
        targetFolderPath: $targetFolderPath
        absolutePaths: $absolutePaths
        description: $description
        activityId: $activityId
        repoId: $repoId
      }
    ) {
      actionId
      actionStatus
    }
  }
`;

export const useExportToSpmSvnHook = () => {
  const [
    exportToSpmSvn,
    {
      data: spmSvnData,
      loading: loadingSpmSvn,
      error: errorSpmSvn,
      reset: resetSpmSvnMutation
    }
  ] = useMutation(MUTATION_EXPORT_TO_SPM_SVN, {
    onError(err) {
      console.log(err);
    }
  });

  return {
    exportToSpmSvn,
    spmSvnData,
    loadingSpmSvn,
    errorSpmSvn,
    resetSpmSvnMutation
  };
};
