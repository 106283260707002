import { gql, useQuery } from "@apollo/client";
export const LIST_ENVIRONMENTS = gql`
  query listEnvironments($activityId: ID!, $environmentId: ID) {
    listEnvironments(
      input: { activityId: $activityId, environmentId: $environmentId }
    ) {
      __typename
      environmentId
      environmentName
      state
      instanceStatus
      systemStatus
      createdBy
      createdAt
      image {
        imageId
        imageName
        platformDetails
        architecture
        createdAt
        software {
          name
          version
        }
      }
      instanceType {
        instanceTypeId
        cpu
        memory
        architectures
      }
      instanceId
      privateIP
    }
  }
`;

export function useQueryEnvironmentsQuery(
  activityId: number,
  polling: boolean = true,
  environmentId?: string
) {
  const {
    data: environments,
    loading: isLoadingEnvironments,
    error: errorListEnvironments,
    refetch: refetchEnvironments
  } = useQuery(LIST_ENVIRONMENTS, {
    variables: {
      activityId: activityId,
      ...(environmentId && { environmentId })
    },
    context: { clientName: "interactive" },
    notifyOnNetworkStatusChange: true,
    ...(polling && { pollInterval: 100000 }) // Query data periodically every 100 sec to update status of the environments
  });
  return {
    environments,
    isLoadingEnvironments,
    errorListEnvironments,
    refetchEnvironments
  };
}
