import SendToMobileIcon from "@mui/icons-material/SendToMobile";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  NavDropdown
} from "react-bootstrap";
import { useExportToModspaceHook } from "../../backend/hooks/importExportMenu/mutationExportToModspace";
import {
  encryptStringWithRsaPublicKey,
  MAXIMUM_CLEAR_PASSWORD
} from "../../helpers/stringHelper";
import FileIcon from "../../components/abstractComponents/fileIcon";
import Error from "../abstractComponents/error";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useGetModspaceContentQuery } from "../../backend/hooks/importExportMenu/queryGetModspaceContent";
import { ModspaceFile } from "../../backend/types";

// Component that renders everything related to Modspace Export
export default function ExportToModspace(props: {
  currentFolder: string;
  disabled: boolean;
  activityId: number;
  refetchRepoObjects: Function;
  selectedRows: any[];
  repository: any;
}) {
  const defaultRootAddressModspaceExport =
    localStorage.getItem("defaultRootAddressModspaceExport") &&
    localStorage.getItem("defaultRootAddressModspaceExport") !== "undefined" &&
    localStorage.getItem("defaultRootAddressModspaceExport") !== ""
      ? String(localStorage.getItem("defaultRootAddressModspaceExport"))
      : "<EntryId> / <EntryName> / <RelativePath>";
  const [rootAddress, setRootAddress] = useState(
    defaultRootAddressModspaceExport
  );
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [description, setDescription] = useState("");
  const [modspaceUsername, setModspaceUsername] = useState(
    localStorage.getItem("modspaceUsername") || ""
  );
  const [modspacePassword, setModspacePassword] = useState(
    localStorage.getItem("modspacePassword") || ""
  );
  const [thereIsAnOverwrite, setThereIsAnOverwrite] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any>([]);

  const { getModspaceContent, error, loading, data } =
    useGetModspaceContentQuery();

  useEffect(() => {
    if (rootAddress && modspaceUsername && modspacePassword) {
      let password;
      if (
        modspacePassword.length < MAXIMUM_CLEAR_PASSWORD &&
        !modspacePassword.endsWith("=")
      ) {
        password = encryptStringWithRsaPublicKey(modspacePassword);
        setModspacePassword(password);
        localStorage.setItem("modspaceUsername", modspaceUsername);
        localStorage.setItem("modspacePassword", password);
      } else {
        password = modspacePassword;
      }

      getModspaceContent({
        variables: {
          modspaceUser: modspaceUsername,
          encryptedPassword: password,
          absolutePath: rootAddress
        }
      });
    }
  }, [rootAddress]);

  useEffect(() => {
    const rows = props.selectedRows.map((name: string) => {
      let willOverwrite = false;

      data?.getModspaceContent.forEach((entry: ModspaceFile) => {
        if (
          (name.endsWith("/") &&
            entry.name.replace("/", "") === name.split("/").at(-2)) ||
          (!name.endsWith("/") && entry.name === name.split("/").at(-1))
        ) {
          willOverwrite = true;
        }
      });

      setThereIsAnOverwrite(willOverwrite);

      return { name: name, willOverwrite: willOverwrite };
    });
    setSelectedRows(rows);
  }, [props.selectedRows, data]);

  const {
    exportToModspace,
    modspaceData,
    loadingModspace,
    errorModspace,
    resetModspaceMutation
  } = useExportToModspaceHook();

  const handleExportToModspace = (password?: string) => {
    exportToModspace({
      variables: {
        modspaceUsername,
        encryptedPassword: password ? password : modspacePassword,
        targetFolderPath: rootAddress,
        absolutePaths: props.selectedRows,
        description,
        activityId: props.activityId,
        repoId: props.repository.id
      }
    });
  };

  useEffect(() => {
    setDescription("");
    //animation is wrong, so we delay
    setTimeout(resetModspaceMutation, 500);
  }, [isExportModalVisible]);

  const expotToModspace = () => {
    if (
      !thereIsAnOverwrite ||
      (thereIsAnOverwrite &&
        window.confirm("Are you sure you want to overwrite files on Modspace?"))
    ) {
      localStorage.setItem("defaultRootAddressModspaceExport", rootAddress);
      if (
        modspacePassword.length > MAXIMUM_CLEAR_PASSWORD &&
        modspacePassword.endsWith("=")
      ) {
        handleExportToModspace();
      } else {
        //Password is not encrypted, so we encrypt it
        const newPassword = encryptStringWithRsaPublicKey(modspacePassword);
        setModspacePassword(newPassword);
        localStorage.setItem("modspaceUsername", modspaceUsername);
        localStorage.setItem("modspacePassword", newPassword);
        handleExportToModspace(newPassword);
      }
    }
  };

  return (
    <>
      <NavDropdown.Item
        size="small"
        onClick={() => {
          setIsExportModalVisible(true);
        }}
        disabled={props.disabled}
        id="exportToModspaceItem"
      >
        <SendToMobileIcon /> Modspace Export
      </NavDropdown.Item>

      <Modal
        show={isExportModalVisible}
        onHide={() => setIsExportModalVisible(false)}
        dialogClassName="modal-80w"
        enforceFocus={false}
        aria-labelledby="exportToModspaceTitle"
      >
        <Modal.Header closeButton>
          <Modal.Title id="exportToModspaceTitle">
            Export to Modspace
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            {(loadingModspace || loading) && (
              <Row>
                <div className="center_div">
                  <Spinner animation="border" className="spinner_color" />
                  <p>Loading...</p>
                </div>
              </Row>
            )}
            {modspaceData ? (
              <div className="center_div">
                <CheckCircleOutlineIcon color="success" fontSize="large" />
                <p>Data export has started!</p>
              </div>
            ) : (
              <>
                <Row
                  className={
                    modspacePassword.length > MAXIMUM_CLEAR_PASSWORD
                      ? "login_grey_color_row"
                      : ""
                  }
                >
                  <Col xs={2}>
                    <Form.Label>Modspace Username:</Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Control
                      type="text"
                      required
                      id="exportToModspaceUsernameFormControl"
                      placeholder="Enter username"
                      value={modspaceUsername}
                      onChange={(e) => setModspaceUsername(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row
                  className={
                    modspacePassword.length > MAXIMUM_CLEAR_PASSWORD
                      ? "login_grey_color_row"
                      : ""
                  }
                >
                  <Col xs={2}>
                    <Form.Label>Modspace Password:</Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Control
                      type="password"
                      required
                      id="exportToModspacePasswordFormControl"
                      placeholder="Enter password"
                      value={modspacePassword}
                      onChange={(e) => setModspacePassword(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={2}>
                    <Form.Label>
                      Modspace path:<span className="red_form">*</span>
                    </Form.Label>
                  </Col>
                  <Col xs={8}>
                    <Form.Control
                      type="text"
                      required
                      id="exportToModspaceRootAddressFormControl"
                      placeholder="Enter Modspace path"
                      value={rootAddress}
                      onChange={(e) => setRootAddress(e.target.value)}
                    />
                  </Col>
                </Row>
                <br />
                <br />
                <Row>
                  <Col>
                    {selectedRows.map((entry: any) => {
                      return (
                        <div
                          data-testid="exportToModspaceFileOutputElement"
                          key={entry.name}
                          className={
                            entry.willOverwrite
                              ? "drop_comment_file drop_comment_file_warning"
                              : "drop_comment_file"
                          }
                        >
                          <FileIcon
                            fileName={entry.name}
                            isValid={false}
                            fontSize={"small"}
                          />{" "}
                          {props.repository.name + "/" + entry.name}
                          {entry.willOverwrite && " (will overwrite)"}
                        </div>
                      );
                    })}
                  </Col>
                  <Col>
                    <Row>
                      <Col xs={4}>
                        <Form.Label>
                          Export Description:<span className="red_form">*</span>
                        </Form.Label>
                      </Col>
                      <Col xs={6}>
                        <Form.Control
                          type="text"
                          as="textarea"
                          required
                          rows={3}
                          id="exportToModspaceDescriptionFormControl"
                          placeholder="Enter description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
            <br />
            <br />
            <Row>
              <Col xs={11}>
                {!modspaceData && (
                  <Button
                    variant="secondary"
                    onClick={() => setIsExportModalVisible(false)}
                    disabled={loadingModspace}
                  >
                    Cancel
                  </Button>
                )}
              </Col>
              <Col xs={1}>
                {modspaceData ? (
                  <Button
                    variant="secondary"
                    onClick={() => setIsExportModalVisible(false)}
                    disabled={loadingModspace}
                    id="closeExportToModspaceButton"
                  >
                    Close
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    id="finishExportToModspaceButton"
                    disabled={
                      !rootAddress ||
                      !modspaceUsername ||
                      !modspacePassword ||
                      loadingModspace ||
                      !description
                    }
                    onClick={expotToModspace}
                  >
                    Export
                  </Button>
                )}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      {(errorModspace || error) && <Error error={errorModspace || error} />}
    </>
  );
}
