import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { GetRepoObjectQueryData } from "../../types";

const MAX_REFETCH_ASYNC = 5;
export const GET_REPO_OBJECTS_QUERY = gql`
  query getRepoObjects($activityId: ID!, $pageNumber: Int, $repoId: Int) {
    getRepoObjects(
      input: {
        activityId: $activityId
        pageNumber: $pageNumber
        repoId: $repoId
      }
    ) {
      nextPageNumber
      nextRepoId
      data {
        repoObjects {
          name
          versionId
        }
        isReadOnlyRepository
        repo {
          id
          name
          isStandard
        }
      }
    }
  }
`;

export const FORCE_REFRESH_MUTATION = gql`
  mutation forceRefreshPmxActivity($activityId: ID!) {
    forceRefreshPmxActivity(input: { activityId: $activityId })
  }
`;

export function useGetRepoObjectsQuery(
  activityId: number,
  forceRefresh: boolean,
  onCompleted: Function,
  skip: boolean = false
) {
  const [isLoadingExternal, setIsLoadingExternal] = useState(false);
  const [filesFinal, setFilesFinal] = useState<any[]>([]);

  const [callRepoObjects, { data: files, loading: isLoading, error }] =
    useLazyQuery(GET_REPO_OBJECTS_QUERY, {
      notifyOnNetworkStatusChange: false,
      fetchPolicy: "no-cache"
    });

  const [
    forceRefreshMutation,
    { data: dataForceRefresh, loading: isLoadingMutation, error: errorMutation }
  ] = useMutation(FORCE_REFRESH_MUTATION, {
    variables: {
      activityId
    }
  });

  const refetch = () => {
    callRepoObjects({
      variables: {
        activityId
      }
    });
  };

  useEffect(() => {
    if (!skip) {
      callRepoObjects({
        variables: {
          activityId
        }
      });
    }
  }, []);

  useEffect(() => {
    setIsLoadingExternal(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (files?.getRepoObjects) {
      const final = [];
      let wasDataInserted = false;

      filesFinal.forEach((data: GetRepoObjectQueryData) => {
        if (data.repo.id === files?.getRepoObjects.data.repo.id) {
          final.push({
            ...data,
            repoObjects: [
              ...data.repoObjects,
              /* eslint-disable-next-line no-unsafe-optional-chaining */
              ...files?.getRepoObjects?.data?.repoObjects
            ]
          });
          wasDataInserted = true;
        } else {
          final.push(data);
        }
      });

      if (!wasDataInserted) {
        final.push(files?.getRepoObjects.data);
      }

      setFilesFinal(final);
      onCompleted(final);

      if (files?.getRepoObjects.nextPageNumber) {
        callRepoObjects({
          variables: {
            activityId,
            pageNumber: files?.getRepoObjects.nextPageNumber,
            repoId: files?.getRepoObjects.nextRepoId
          }
        });
      } else if (files?.getRepoObjects.nextRepoId) {
        callRepoObjects({
          variables: {
            activityId,
            repoId: files?.getRepoObjects.nextRepoId
          }
        });
      }
    }
  }, [files]);

  useEffect(() => {
    if (forceRefresh) {
      setFilesFinal([]);
      forceRefreshMutation();
    }
  }, [forceRefresh]);

  useEffect(() => {
    if (dataForceRefresh) {
      refetch();

      if (dataForceRefresh?.forceRefreshPmxActivity) {
        //refresh every 1.5 min, for 8 min
        let currentRunNumber = 0;
        const interval = setInterval(() => {
          refetch();
          currentRunNumber++;
          if (currentRunNumber >= MAX_REFETCH_ASYNC) {
            clearInterval(interval);
          }
        }, 90 * 1000);
      }
    }
  }, [dataForceRefresh]);

  return {
    files: filesFinal,
    isLoading: isLoadingExternal || isLoadingMutation,
    error: error || errorMutation,
    dataForceRefresh: dataForceRefresh?.forceRefreshPmxActivity,
    refetchRepoObjects: () => {
      return new Promise(function (resolve: Function) {
        setIsLoadingExternal(true);
        setTimeout(() => {
          refetch();
        }, 3500); // 3s is the time it takes the s3 lambda function to run cold
        resolve();
      });
    }
  };
}
