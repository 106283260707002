import { gql, useMutation } from "@apollo/client";

export const MUTATION_EXPORT_TO_MODSPACE = gql`
  mutation exportToModspace(
    $modspaceUsername: String!
    $encryptedPassword: String!
    $targetFolderPath: String!
    $absolutePaths: [String!]!
    $description: String
    $activityId: ID!
    $repoId: ID!
  ) {
    exportToModspace(
      input: {
        modspaceUsername: $modspaceUsername
        encryptedPassword: $encryptedPassword
        targetFolderPath: $targetFolderPath
        absolutePaths: $absolutePaths
        description: $description
        activityId: $activityId
        repoId: $repoId
      }
    ) {
      actionId
      actionStatus
    }
  }
`;

export const useExportToModspaceHook = () => {
  const [
    exportToModspace,
    {
      data: modspaceData,
      loading: loadingModspace,
      error: errorModspace,
      reset: resetModspaceMutation
    }
  ] = useMutation(MUTATION_EXPORT_TO_MODSPACE, {
    onError(err) {
      console.log(err);
    }
  });

  return {
    exportToModspace,
    modspaceData,
    loadingModspace,
    errorModspace,
    resetModspaceMutation
  };
};
