import { gql, useMutation } from "@apollo/client";

export const MUTATION_IMPORT_MODSPACE_CONTENT = gql`
  mutation importModspaceContent(
    $modspaceUser: String!
    $encryptedPassword: String!
    $absoluteModspacePaths: [String]!
    $modspaceParentFolder: String!
    $repoParentFolder: String!
    $activityId: ID!
    $qualityCheckStatus: QualityCheckStatus
    $description: String
  ) {
    importModspaceContent(
      input: {
        modspaceUser: $modspaceUser
        encryptedPassword: $encryptedPassword
        modspaceParentFolder: $modspaceParentFolder
        absoluteModspacePaths: $absoluteModspacePaths
        repoParentFolder: $repoParentFolder
        activityId: $activityId
        qualityCheckStatus: $qualityCheckStatus
        description: $description
      }
    ) {
      actionId
      actionStatus
      imported {
        name
      }
    }
  }
`;

export function useImportContentFromModspaceMutation() {
  const [
    importContentMutation,
    {
      data: modspaceImportMutationData,
      loading: isLoadingUploadMutation,
      error: errorUploadMutation
    }
  ] = useMutation(MUTATION_IMPORT_MODSPACE_CONTENT, {
    onError(err: any) {
      console.log(err);
    }
  });

  return {
    importContentMutation,
    modspaceImportMutationData,
    isLoadingUploadMutation,
    errorUploadMutation
  };
}
