import { useMutation } from "@apollo/client";
import Download from "@mui/icons-material/Download";
import Preview from "@mui/icons-material/Preview";
import RestoreIcon from "@mui/icons-material/Restore";
import Box from "@mui/material/Box";
import { lighten } from "@mui/material/styles";
import {
  DataGridPro,
  GridActionsCellItem,
  GridCellParams,
  GridRowId,
  GridSortDirection,
  GridSortModel,
  GridValueGetterParams
} from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { Button, Container, Form, Modal, Spinner } from "react-bootstrap";
import { UPDATE_ACTION_STATUS_MUTATION } from "../../backend/hooks/action/mutationUpdateActionStatus";
import { useConsumingActionsLazyQuery } from "../../backend/hooks/contextMenu/queryGetConsumingActions";
import { useCreationalActionsLazyQuery } from "../../backend/hooks/contextMenu/queryGetCreationalActions";
import { useHistoryVersionsLazyQuery } from "../../backend/hooks/contextMenu/queryGetHistoryVersions";
import { useRestoreFileVersionMutation } from "../../backend/hooks/history/mutationRestoreFileVersion";
import { useDownloadLinksQuery } from "../../backend/hooks/importExportMenu/queryGetDownloadLinks";
import {
  ActionStatus,
  GetRepoObjectQueryData,
  PmxActivity,
  RepoObject
} from "../../backend/types";
import { formatFileSize } from "../../helpers/stringHelper";
import { customFilters } from "../../helpers/tableViewHelper";
import ActionTable from "../abstractComponents/actionTable";
import Error from "../abstractComponents/error";
import Success from "../abstractComponents/success";
import UserManualLink from "../abstractComponents/usermanual";

// Component that appears when we go to a file inside ot Table Veiw -> Select a file -> Properties
export default function ActionsOfFiles(props: {
  activity: PmxActivity;
  onNodeSelect: Function;
  onClose: Function;
  absolutePath: String;
  versionId: String;
  isPreselectedRevision: Boolean;
  setIsPreselectedRevision: Function;
  currentFileList: GetRepoObjectQueryData;
  refetch?: Function;
  isHistoricalView?: Boolean;
}) {
  // Set functions
  const columnsFileVersions = [
    {
      field: "revision",
      headerName: "Revision",
      flex: 1,
      filterOperators: customFilters,
      valueGetter: (params: GridCellParams) =>
        params.row.md5 ? params.row.revision : params.row.revision + " (hidden)"
    },
    {
      field: "name",
      headerName: "Path",
      flex: 1,
      filterOperators: customFilters,
      renderCell: (params: any) => (
        <div className="action_list_element">{params.row.name}</div>
      )
    },
    {
      field: "created",
      headerName: "Created At",
      flex: 1,
      filterOperators: customFilters
    },
    {
      field: "createdBy",
      headerName: "User",
      flex: 1,
      filterOperators: customFilters
    },
    {
      field: "size",
      headerName: "Size",
      flex: 1,
      filterOperators: customFilters,
      valueGetter: (params: GridValueGetterParams) =>
        formatFileSize(Number(params.row.size))
    },
    {
      field: "isValid",
      headerName: "Validity",
      flex: 1,
      filterOperators: customFilters
    },
    {
      field: "md5",
      headerName: "MD5",
      flex: 1,
      filterOperators: customFilters
    },
    {
      field: "actions",
      type: "actions",
      flex: 1,
      filterOperators: customFilters,
      getActions: (params: GridValueGetterParams) => [
        <GridActionsCellItem
          icon={
            <RestoreIcon
              className="restore_from_version_id"
              style={{
                fontSize: 40
              }}
            />
          }
          label="Restore"
          title="Restore"
          onClick={() => {
            setVersionIdToBeRestored(params.row.versionId);
            setIsRestoreModalVisible(true);
            setAbsolutePathToBeRestored(params.row.name);
          }}
          onResize={() => {}}
          onResizeCapture={() => {}}
          showInMenu={false}
          key={"restore" + params.row.versionId}
        />,
        <GridActionsCellItem
          icon={
            <Preview
              data-testid={getPreviewURL(params.row.versionId, params.row.name)}
              className="preview_button"
              style={{
                fontSize: 40
              }}
            />
          }
          label="Preview"
          title="Preview"
          onClick={() => handlePreview(params.row.versionId, params.row.name)}
          onResize={() => {}}
          onResizeCapture={() => {}}
          showInMenu={false}
          key={"preview_button" + params.row.versionId}
        />,
        <GridActionsCellItem
          icon={
            <Download
              className="download_button"
              style={{
                fontSize: 40
              }}
            />
          }
          label="Download"
          title="Download"
          onClick={() =>
            handleDownloadFile(params.row.name, params.row.versionId)
          }
          onResize={() => {}}
          onResizeCapture={() => {}}
          showInMenu={false}
          key={"download_button" + params.row.versionId}
        />
      ]
    }
  ];

  const [rowsCreationalActions, setRowsCreationalActions] = useState<any[]>([]);
  const [revisionsToId, setRevisionsToId] = useState<any>({});
  const [rowsConsumingActions, setRowsConsumingActions] = useState<any[]>([]);
  const [versionIds, setVersionIds] = useState<string[]>([]);
  const [selectedRevision, setSelectedRevision] = useState("");
  const [selectedVersionId, setSelectedVersionId] = useState("");
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);

  const [sortModelVersions, setSortModelVersions] = useState<GridSortModel>([
    {
      field: "created",
      sort: "desc" as GridSortDirection
    }
  ]);

  // Query functions
  const { getFileVersions, fileVersions, isLoading, error } =
    useHistoryVersionsLazyQuery();

  const {
    getCreationalActions,
    creationalActions,
    isLoadingCreationalActions,
    errorCreationalActions,
    refetchCreationalActions
  } = useCreationalActionsLazyQuery();

  const refetchAllActions = () => {
    refetchCreationalActions();
    refetchConsumingActions();
    if (props.refetch) {
      props.refetch();
    }
  };

  const [comment, setComment] = useState("");
  const [isRestoreModalVisible, setIsRestoreModalVisible] = useState(false);
  const [versionIdToBeRestored, setVersionIdToBeRestored] =
    useState<String | null>();
  const [absolutePathToBeRestored, setAbsolutePathToBeRestored] =
    useState<String | null>();

  const {
    restoreFileVersionMutation,
    isLoadingRestoreMutation,
    errorRestoreMutation,
    dataMutation
  } = useRestoreFileVersionMutation();

  const {
    getConsumingActions,
    consumingActions,
    isLoadingConsumingActions,
    errorConsumingActions,
    refetchConsumingActions
  } = useConsumingActionsLazyQuery();

  const { getDownloadLinkForFiles, downloadUrls, errorDownload } =
    useDownloadLinksQuery();

  const [updateActionStatus, { data: statusForUpdatedAction }] = useMutation(
    UPDATE_ACTION_STATUS_MUTATION,
    {
      onError(err: any) {
        console.log(err);
      }
    }
  );

  const handleDownloadFile = (absolutePath: string, versionId: string) => {
    getDownloadLinkForFiles({
      variables: {
        absolutePaths: [absolutePath],
        activityId: Number(props.activity.id),
        repoId: Number(props.currentFileList.repo.id),
        versionIds: [versionId]
      }
    });
  };

  const handleGetRowIdVersions = (row: any) => {
    return row.versionId;
  };

  const getPreviewURL = (versionId: string, absolutePath: string) => {
    return (
      "/activity/" +
      props.activity.id +
      encodeURIComponent("/" + absolutePath) +
      encodeURIComponent("?versionId=" + versionId)
    );
  };

  const handlePreview = (versionId: string, absolutePath: string) => {
    window.location.href = getPreviewURL(versionId, absolutePath);
  };

  const handleCellClick = (params: GridCellParams) => {
    if (
      params.row.versionId !== selectedVersionId &&
      params.field !== "actions" &&
      params.row.name
    ) {
      setSelectedRevision(params.row.revision.toString());
      setSelectedVersionId(params.row.versionId);

      const filteredCreationalActions = [];
      for (const action of creationalActions.getCreationalActions) {
        for (const af of action.actionFile) {
          if (
            params.row.versionId.includes(af.file.versionId) &&
            af.actionFileType !== "Input"
          ) {
            filteredCreationalActions.push(action);
          }
        }
      }
      setRowsCreationalActions(filteredCreationalActions);

      const filteredConsumingActions = [];
      for (const action of consumingActions.getConsumingActions) {
        for (const af of action.actionFile) {
          if (
            params.row.versionId.includes(af.file.versionId) &&
            af.actionFileType === "Input"
          ) {
            filteredConsumingActions.push(action);
          }
        }
      }
      setRowsConsumingActions(filteredConsumingActions);
    } else {
      setSelectedRevision("");
      setSelectedVersionId("");
      setRowsCreationalActions(creationalActions.getCreationalActions);
      setRowsConsumingActions(consumingActions.getConsumingActions);
    }
  };

  useEffect(() => {
    if (typeof downloadUrls !== "undefined") {
      if (downloadUrls.getDownloadLinks.actionStatus === "Pending") {
        try {
          downloadUrls?.getDownloadLinks?.presignedUrls.forEach((url: any) => {
            window.open(url.url);
          });
          updateActionStatus({
            variables: {
              actionId: downloadUrls.getDownloadLinks.actionId,
              actionStatus: ActionStatus.Success
            }
          });
        } catch (err: any) {
          updateActionStatus({
            variables: {
              actionId: downloadUrls.getDownloadLinks.actionId,
              actionStatus: ActionStatus.Error,
              actionStatusDescription: err.message
            }
          });
        }
      }
    }
  }, [downloadUrls]);

  useEffect(() => {
    if (
      statusForUpdatedAction &&
      statusForUpdatedAction?.updateActionStatus.actionStatus === "Success"
    ) {
      getConsumingActions({
        variables: {
          versionIds: versionIds,
          activityId: props.activity.id
        }
      });
    }
  }, [statusForUpdatedAction]);

  // Query file versions
  useEffect(() => {
    if (props.versionId) {
      getFileVersions({
        variables: {
          repoId: props.currentFileList.repo.id,
          activityId: Number(props.activity.id),
          versionId: props.versionId
        }
      });
    }
  }, [props.versionId, props.absolutePath]);

  useEffect(() => {
    if (fileVersions && fileVersions.getHistoryVersions.length > 0) {
      const versionIds = fileVersions.getHistoryVersions.map(
        (fileVersion: RepoObject) => fileVersion.versionId
      );
      setVersionIds(versionIds);
    }
  }, [fileVersions]);

  useEffect(() => {
    if (versionIds && versionIds.length > 0) {
      getCreationalActions({
        variables: {
          versionIds: versionIds,
          activityId: props.activity.id
        }
      });
      getConsumingActions({
        variables: {
          versionIds: versionIds,
          activityId: props.activity.id
        }
      });
    }
  }, [versionIds]);

  useEffect(() => {
    if (
      !isLoadingCreationalActions &&
      creationalActions &&
      creationalActions.getCreationalActions.length > 0
    ) {
      // Set revision number to action id
      const revisionsToIdLoop = {};
      for (const action of creationalActions.getCreationalActions) {
        const actionId = action.id;
        for (const af of action.actionFile) {
          if (
            versionIds.includes(af.file.versionId) &&
            af.actionFileType !== "Input"
          ) {
            const tmpRevision: { [key: string]: string } = {
              [actionId]: af.file.revision
            };
            Object.assign(revisionsToIdLoop, tmpRevision);
          }
        }
      }

      setRevisionsToId({ ...revisionsToId, ...revisionsToIdLoop });

      setRowsCreationalActions(creationalActions.getCreationalActions);
    }
  }, [creationalActions]);

  useEffect(() => {
    if (
      !isLoadingConsumingActions &&
      consumingActions &&
      consumingActions.getConsumingActions.length > 0
    ) {
      // Set revision number to action id
      const revisionsToIdLoop = {};
      for (const action of consumingActions.getConsumingActions) {
        const actionId = action.id;
        for (const af of action.actionFile) {
          if (
            versionIds.includes(af.file.versionId) &&
            af.actionFileType === "Input"
          ) {
            const obj: { [key: string]: string } = {
              [actionId]: af.file.revision
            };
            Object.assign(revisionsToIdLoop, obj);
          }
        }
      }

      setRevisionsToId({ ...revisionsToId, ...revisionsToIdLoop });

      setRowsConsumingActions(consumingActions.getConsumingActions);
    }
  }, [consumingActions, isLoadingConsumingActions]);

  useEffect(() => {}, [
    consumingActions,
    creationalActions,
    fileVersions,
    selectedRevision
  ]);

  const restoreSelectedPaths = () => {
    restoreFileVersionMutation({
      variables: {
        versionIds: [versionIdToBeRestored],
        activityId: props.activity.id,
        comment: comment
      }
    }).then(() => {
      window.location.href =
        "/activity/" +
        props.activity.id +
        encodeURIComponent("/" + absolutePathToBeRestored) +
        encodeURIComponent(
          "?repoId=" + props.currentFileList.repo.id + "&openProperties=true"
        );
    });
    setIsRestoreModalVisible(false);
  };

  useEffect(() => {
    props.versionId &&
      props.isPreselectedRevision &&
      setSelectionModel([props.versionId as string]);
  }, [props.versionId, props.isPreselectedRevision]);

  return (
    <>
      <Modal
        enforceFocus={false}
        show
        onHide={() => props.onClose()}
        dialogClassName="modal-80w"
        aria-labelledby="custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="custom-modal-styling-title">
            Properties of {props.absolutePath}
          </Modal.Title>
          <UserManualLink
            url="/usermanual/properties/"
            testId="um-properties"
            className="align-self-start ms-1"
          />
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <>
              {isLoading ||
              isLoadingCreationalActions ||
              isLoadingConsumingActions ||
              isLoadingRestoreMutation ? (
                <div className="center_div">
                  <Spinner animation="border" className="spinner_color" />
                  <p>Loading...</p>
                </div>
              ) : error ||
                errorCreationalActions ||
                errorConsumingActions ||
                errorDownload ||
                errorRestoreMutation ? (
                <Error
                  error={
                    error ||
                    errorCreationalActions ||
                    errorConsumingActions ||
                    errorDownload ||
                    errorRestoreMutation
                  }
                />
              ) : (
                <>
                  {fileVersions && fileVersions.getHistoryVersions && (
                    <>
                      <h5>File Versions:</h5>
                      <div className="file_versions_table">
                        <Box
                          sx={{
                            width: 1,
                            "& .super-app-theme--Hidden": {
                              bgcolor: lighten("#e4a3a3", 0.6),
                              "&:hover": {
                                bgcolor: lighten("#e4a3a3", 0.5)
                              }
                            }
                          }}
                        >
                          {/** Historical View File Peopertis will always display the latest entry as not hidden, irrespective of the current status **/}
                          <DataGridPro
                            rows={
                              props.isHistoricalView
                                ? [
                                    {
                                      ...fileVersions.getHistoryVersions.at(0),
                                      md5: fileVersions.getHistoryVersions.at(0)
                                        .md5
                                        ? fileVersions.getHistoryVersions.at(0)
                                        : "   "
                                    },
                                    ...fileVersions.getHistoryVersions.filter(
                                      (item: any) =>
                                        item.versionId !==
                                        fileVersions.getHistoryVersions.at(0)
                                          .versionId
                                    )
                                  ]
                                : fileVersions.getHistoryVersions
                            }
                            className="styled_data_grid"
                            columns={columnsFileVersions}
                            pageSize={25}
                            autoHeight
                            getRowHeight={() => "auto"}
                            checkboxSelection
                            selectionModel={selectionModel}
                            getRowClassName={(params) =>
                              params.row.md5 ? "" : "super-app-theme--Hidden"
                            }
                            hideFooterSelectedRowCount
                            getRowId={handleGetRowIdVersions}
                            onCellClick={handleCellClick}
                            onSelectionModelChange={(selection) => {
                              if (selection.length > 1) {
                                const selectionSet = new Set(selectionModel);
                                const result = selection.filter(
                                  (s) => !selectionSet.has(s)
                                );
                                setSelectionModel(result);
                              } else {
                                setSelectionModel(selection);
                              }
                            }}
                            sortModel={sortModelVersions}
                            onSortModelChange={(model) =>
                              setSortModelVersions(model)
                            }
                          />
                        </Box>
                      </div>

                      <Modal
                        size="lg"
                        show={isRestoreModalVisible}
                        onHide={() => setIsRestoreModalVisible(false)}
                        className="modal_over_popup"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Restore Item</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicName"
                          >
                            <Form.Label>
                              Comment <span className="red_form">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              required
                              placeholder="Comment"
                              id="set_comment"
                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                            />
                          </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={() => setIsRestoreModalVisible(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="primary"
                            disabled={comment === ""}
                            onClick={restoreSelectedPaths}
                          >
                            <RestoreIcon /> Restore Item
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </>
                  )}
                  {creationalActions &&
                    creationalActions.getCreationalActions.length > 0 && (
                      <>
                        <br />
                        <h5>Creating Actions:</h5>
                        <div className="creational_actions_table">
                          <ActionTable
                            rowsActions={rowsCreationalActions}
                            refetch={refetchAllActions}
                            selectedRevision={selectedRevision}
                            setIsPreselectedRevision={
                              props.setIsPreselectedRevision
                            }
                            revisionsToId={revisionsToId}
                            onNodeSelect={props.onNodeSelect}
                            onClose={props.onClose}
                          />
                        </div>
                      </>
                    )}
                  {consumingActions &&
                  consumingActions.getConsumingActions.length > 0 ? (
                    <>
                      <br />
                      <h5>Consuming Actions:</h5>
                      <div className="consuming_actions_table">
                        <ActionTable
                          rowsActions={rowsConsumingActions}
                          refetch={refetchAllActions}
                          selectedRevision={selectedRevision}
                          setIsPreselectedRevision={
                            props.setIsPreselectedRevision
                          }
                          revisionsToId={revisionsToId}
                          onNodeSelect={props.onNodeSelect}
                          onClose={props.onClose}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <br />
                      <p>No Consuming Actions</p>
                    </>
                  )}
                </>
              )}
            </>
          </Container>
        </Modal.Body>
      </Modal>
      {dataMutation && <Success message="File was restored" />}
    </>
  );
}
