import LocalActivityOutlinedIcon from "@mui/icons-material/LocalActivityOutlined";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  ButtonGroup,
  ToggleButton
} from "react-bootstrap";
import { useAddActivityMutation } from "../../backend/hooks/pmxActivity/mutationCreateActivity";
import { useGetMyPmxActivitiesQuery } from "../../backend/hooks/pmxActivity/queryGetMyPmxActivities";
import { PmxActivity } from "../../backend/types";
import { useUserHelper } from "../../hooks/userHelper";
import Error from "../abstractComponents/error";
import { ListActivities } from "./listActivities";
import { DataGridPro, GridSelectionModel } from "@mui/x-data-grid-pro";
import { customFilters } from "../../helpers/tableViewHelper";
import { useAuth } from "../authContextProvider";
import UserManualLink from "../abstractComponents/usermanual";

// Component used to provide the posibility to create activities, and a parent for the list of PMX Activities

const TRY_TIMES_BEFORE_QUIT = 3;
const MILISECONDS_BETWEEN_TRIES = 3000;

export default function Activities() {
  const [isCreateWindowOpen, setIsCreateWindowOpen] = useState(false);
  const [newActivityTrialNumber, setNewActivityTrialNumber] = useState("");
  const [isTrialNumberInvalid, setIsTrialNumberInvalid] = useState(false);
  const [trialNumberError, setTrialNumberError] = useState("");
  const [newRepository, setNewRepository] = useState("");
  const [bayNumber, setBayNumber] = useState("");
  const [isBayNumberInvalid, setIsBayNumberInvalid] = useState(false);
  const [bayNumberError, setBayNumberError] = useState("");
  const [projectId, setProjectId] = useState("");
  const [isProjectIdInvalid, setIsProjectIdInvalid] = useState(false);
  const [projectIdError, setProjectIdError] = useState("");
  const [trialShortDescription, setTrialShortDescription] = useState("");
  const [finalError, setFinalError] = useState("");
  const [retryTimes, setRetryTimes] = useState(0);
  const [isLoadingFinal, setIsLoadingFinal] = useState(true);
  const { isBayerUser } = useUserHelper();
  const [isNewRepositoryStandard, setIsNewRepositoryStandard] = useState(true);
  const [readOnlyRepoIds, setReadOnlyRepoIds] = useState<GridSelectionModel>(
    []
  );
  const alphaNumericRegex = /[^a-zA-Z0-9.:\\+-=@_/ ]/;
  const { isDataAccessManager } = useAuth();

  useEffect(() => {
    document.title = "PHIL App - My Activities";
  }, []);

  function validateAlphaNumeric(
    value: string,
    setState: React.Dispatch<React.SetStateAction<string>>,
    setInvalid: React.Dispatch<React.SetStateAction<boolean>>,
    setError: React.Dispatch<React.SetStateAction<string>>
  ) {
    value = value.trim();
    setState(value);
    if (value.match(alphaNumericRegex)) {
      setInvalid(true);
      setError(
        "Must only contain letters, numbers, spaces or the following symbols: .:+=@_/"
      );
    } else if (value.includes("aws")) {
      setInvalid(true);
      setError("Must not start with 'aws'.");
    } else {
      setInvalid(false);
    }
  }

  const { addNewActivity, isLoadingMutation, errorMutation } =
    useAddActivityMutation();

  const { activities, error, refetchActivites } =
    useGetMyPmxActivitiesQuery(false);

  function saveNewActivity() {
    addNewActivity({
      variables: {
        repoId: newRepository,
        trialNumber: newActivityTrialNumber,
        bayNumber,
        trialShortDescription,
        projectId,
        isStandard: isNewRepositoryStandard,
        readOnlyRepositories: readOnlyRepoIds.map((id) => Number(id))
      }
    }).then(() => {
      if (!error) {
        refetchActivites();
        setIsCreateWindowOpen(false);
        setNewRepository("");
        setNewActivityTrialNumber("");
      }
    });
  }

  // we call the API 3 times before showing an error, so Lambda will not always fail
  useEffect(() => {
    if (error?.message) {
      if (retryTimes < TRY_TIMES_BEFORE_QUIT) {
        setTimeout(function () {
          refetchActivites();
          setRetryTimes(retryTimes + 1);
        }, MILISECONDS_BETWEEN_TRIES);
      } else {
        setFinalError(error?.message);
        setIsLoadingFinal(false);
      }
    }
  }, [error, retryTimes]);

  useEffect(() => {
    if (activities) {
      setIsLoadingFinal(false);
    }
  }, [activities]);

  return (
    <div className="app_content">
      {(isLoadingFinal || isLoadingMutation) && (
        <div className="center_div">
          <Spinner animation="border" className="spinner_color" />
          <p>{error ? "Retrying..." : "Loading..."}</p>
        </div>
      )}
      {!isLoadingFinal && !isLoadingMutation && (
        <Container fluid>
          <Row>
            <Col xs={10}>
              <div className="d-inline-flex">
                <h2>My Activities</h2>
                <UserManualLink
                  url="/usermanual/concepts/#pmxactivities"
                  testId="um-activities"
                />
              </div>
            </Col>
            {isBayerUser && (
              <Col>
                <Button
                  onClick={() => setIsCreateWindowOpen(true)}
                  id="create_new_activity"
                  className="float-end button-secondary"
                  data-testid="create_new_activity"
                >
                  <LocalActivityOutlinedIcon /> Create New
                </Button>
              </Col>
            )}
          </Row>
          <br />
          <Row>
            <ListActivities
              activities={activities?.getMyPmxActivities}
              refetchActivities={refetchActivites}
            />
          </Row>

          <Modal
            show={isCreateWindowOpen}
            onHide={() => setIsCreateWindowOpen(false)}
            dialogClassName="modal-75w"
          >
            <Modal.Header closeButton>
              <Modal.Title>Create New Activity</Modal.Title>
              <UserManualLink
                url="/usermanual/createrepos/"
                testId="um-new-activity"
                className="align-self-start ms-1"
              />
            </Modal.Header>
            <Modal.Body>
              <Form.Group
                className="mb-3"
                key="formTrialId"
                controlId="formTrialId"
              >
                <Form.Label>
                  Activity Name <span className="red_form">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  isInvalid={isTrialNumberInvalid}
                  placeholder="Activity Name"
                  value={newActivityTrialNumber}
                  onChange={(e) =>
                    validateAlphaNumeric(
                      e.target.value,
                      setNewActivityTrialNumber,
                      setIsTrialNumberInvalid,
                      setTrialNumberError
                    )
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {trialNumberError}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                className="mb-3"
                key="formBasicBayNumber"
                controlId="formBasicBayNumber"
                data-testid="formBasicBayNumber"
              >
                <Form.Label>BayNumber</Form.Label>
                <Form.Control
                  type="text"
                  required
                  isInvalid={isBayNumberInvalid}
                  placeholder="Enter BayNumber"
                  value={bayNumber}
                  onChange={(e) =>
                    validateAlphaNumeric(
                      e.target.value,
                      setBayNumber,
                      setIsBayNumberInvalid,
                      setBayNumberError
                    )
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {bayNumberError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="mb-3"
                key="formBasicProjectId"
                controlId="formBasicProjectId"
              >
                <Form.Label>Project Id</Form.Label>
                <Form.Control
                  type="text"
                  required
                  isInvalid={isProjectIdInvalid}
                  placeholder="Enter Project ID"
                  value={projectId}
                  onChange={(e) =>
                    validateAlphaNumeric(
                      e.target.value,
                      setProjectId,
                      setIsProjectIdInvalid,
                      setProjectIdError
                    )
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {projectIdError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="mb-3"
                key="formTrialDescription"
                controlId="formTrialDescription"
              >
                <Form.Label>Activity Short Description</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  required
                  placeholder="Enter Activity Short Description"
                  value={trialShortDescription}
                  onChange={(e) => setTrialShortDescription(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                key="readOnlyRepositories"
                controlId="readOnlyRepositories"
              >
                <Form.Label>Read only PMX Activities attached</Form.Label>
                <DataGridPro
                  rows={
                    activities
                      ? activities?.getMyPmxActivities.filter(
                          (activity: PmxActivity) =>
                            activity.mainRepository.isStandard
                        )
                      : []
                  }
                  columns={[
                    {
                      field: "trialNumber",
                      headerName: "Trial Number",
                      width: 300,
                      filterOperators: customFilters
                    },
                    { field: "bayNumber", headerName: "BayNumber", width: 200 },
                    {
                      field: "projectId",
                      headerName: "Project ID",
                      width: 200,
                      filterOperators: customFilters
                    },
                    {
                      field: "trialShortDescription",
                      headerName: "Trial Description",
                      width: 150,
                      filterOperators: customFilters
                    }
                  ]}
                  rowHeight={60}
                  pageSize={10}
                  getRowId={(row) => row.mainRepository.id}
                  selectionModel={readOnlyRepoIds}
                  onSelectionModelChange={(newSelectionModel) => {
                    setReadOnlyRepoIds(newSelectionModel);
                  }}
                  autoHeight
                  checkboxSelection
                  className="choose_read_only_repos"
                />
              </Form.Group>
              {isDataAccessManager && (
                <ButtonGroup>
                  <ToggleButton
                    type="radio"
                    variant={"outline-primary"}
                    name="isNewRepositoryStandard"
                    id="standardPublicRepository"
                    value="true"
                    checked={isNewRepositoryStandard}
                    onClick={() => setIsNewRepositoryStandard(true)}
                  >
                    Standard PMx public
                  </ToggleButton>
                  <ToggleButton
                    type="radio"
                    variant={"outline-primary"}
                    name="isNewRepositoryStandard"
                    id="accessRestrictedRepository"
                    data-testid="accessRestrictedRepository"
                    value="false"
                    checked={!isNewRepositoryStandard}
                    onClick={() => setIsNewRepositoryStandard(false)}
                  >
                    Access restricted
                  </ToggleButton>
                </ButtonGroup>
              )}
              <br />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setIsCreateWindowOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={
                  newActivityTrialNumber === "" ||
                  isTrialNumberInvalid ||
                  isBayNumberInvalid ||
                  isProjectIdInvalid
                }
                onClick={saveNewActivity}
              >
                Create New
              </Button>
            </Modal.Footer>
          </Modal>
          <br />
        </Container>
      )}
      {(finalError || errorMutation) && (
        <Error error={finalError || errorMutation} />
      )}
    </div>
  );
}
