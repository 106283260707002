import FolderIcon from "@mui/icons-material/Folder";
import FileIcon from "../../components/abstractComponents/fileIcon";
import AddToHomeScreenIcon from "@mui/icons-material/AddToHomeScreen";
import MaterialButton from "@mui/material/Button";
import { DataGridPro, GridRowModel } from "@mui/x-data-grid-pro";
import { useEffect, useState, useContext } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  NavDropdown
} from "react-bootstrap";
import { useImportContentFromModspaceMutation } from "../../backend/hooks/importExportMenu/mutationImportModspaceContent";
import { useGetModspaceContentQuery } from "../../backend/hooks/importExportMenu/queryGetModspaceContent";
import { ModspaceFile } from "../../backend/types";
import {
  encryptStringWithRsaPublicKey,
  formatFileSize,
  MAXIMUM_CLEAR_PASSWORD,
  getQualityCheckStatusKeyFromValue,
  QualityCheckStatus,
  QualityCheckStatusType
} from "../../helpers/stringHelper";
import Error from "../abstractComponents/error";
import Success from "../abstractComponents/success";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { customFilters } from "../../helpers/tableViewHelper";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";

// Component that renders everything related to Modspace Import
export default function ImportFromModspace(props: {
  currentFolder: string;
  disabled: boolean;
  refetchRepoObjects: Function;
  currentSubArray: [any];
}) {
  const { activityId, activityData } = useContext(PmxActivityDetailsContext);
  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 4,
      filterOperators: customFilters,
      renderCell: (params: any) => {
        let weHaveSameName = false;
        props.currentSubArray?.forEach((element: any) => {
          if (element.name === params.row.name) {
            weHaveSameName = true;
          }
        });
        return (
          <div key={params.row.name} style={{ width: "100%" }}>
            {params.row.isDir ? (
              <MaterialButton
                variant="text"
                onClick={() => onClickOnFileOrFolder(params.row.id)}
                className="button_without_text_transform float-start"
              >
                <>
                  <FolderIcon
                    className="grey_color folder_icon"
                    style={{ fontSize: 50 }}
                  />
                  <span className="folder_name_table_view_span">
                    {params.row.name}
                  </span>
                </>
              </MaterialButton>
            ) : (
              <>
                <FileIcon
                  fileName={params.row.name}
                  isValid={false}
                  fontSize={"large"}
                  className={weHaveSameName ? "warning_clean" : ""}
                />
                {params.row.name + (weHaveSameName ? " (name exists)" : "")}
              </>
            )}
          </div>
        );
      },
      valueGetter: (params: any) => params.row.name
    },
    {
      field: "size",
      headerName: "Size",
      flex: 2,
      filterOperators: customFilters,
      valueGetter: (params: any) => params.row.size,
      renderCell: (params: any) =>
        params.row.isDir ? "" : formatFileSize(params.row.size),
      sortComparator: (v1: any, v2: any, param1: any, param2: any) =>
        (param1.api.getCellValue(param1.id, "size") as number) -
        (param2.api.getCellValue(param2.id, "size") as number)
    }
  ];

  const defaultRootAddressModspaceImport =
    localStorage.getItem("defaultRootAddressModspaceImport") &&
    localStorage.getItem("defaultRootAddressModspaceImport") !== "undefined" &&
    localStorage.getItem("defaultRootAddressModspaceImport") !== ""
      ? String(localStorage.getItem("defaultRootAddressModspaceImport"))
      : "/entry ID/entry name/";
  const [rootAddress, setRootAddress] = useState(
    defaultRootAddressModspaceImport
  );
  const [weHaveSameNameSomewhere, setWeHaveSameNameSomewhere] = useState(false);
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const [qualityCheckStatus, setQualityCheckStatus] =
    useState<QualityCheckStatus | null>(null);
  const [description, setDescription] = useState("");
  const [modspaceUsername, setModspaceUsername] = useState(
    localStorage.getItem("modspaceUsername") || ""
  );
  const [modspacePassword, setModspacePassword] = useState(
    localStorage.getItem("modspacePassword") || ""
  );
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  useEffect(() => {
    setWeHaveSameNameSomewhere(false);
    props.currentSubArray?.forEach((element: any) => {
      selectedRows.forEach((selectedElement: string) => {
        if (element.name === selectedElement.split("/").slice(-1)[0]) {
          setWeHaveSameNameSomewhere(true);
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  // We actually need the effect to trigger only when the popup is shown, so we donät put more stress on the server
  useEffect(() => {
    if (
      isImportModalVisible &&
      rootAddress &&
      modspaceUsername &&
      modspacePassword
    ) {
      handleLoadModspaceFolder(rootAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isImportModalVisible]);

  const handleLoadModspaceFolder = (
    absolutePath: string,
    password?: string
  ) => {
    getModspaceContent({
      variables: {
        modspaceUser: modspaceUsername,
        encryptedPassword: password ? password : modspacePassword,
        absolutePath
      }
    });
  };

  const { getModspaceContent, error, loading, data } =
    useGetModspaceContentQuery();

  const onClickOnFileOrFolder = (address: string) => {
    setRootAddress(address);
    fetchModspaceData();
    localStorage.setItem("defaultRootAddressModspaceImport", address);
    handleLoadModspaceFolder(address);
  };

  const clickOnBack = () => {
    const newAddress = rootAddress.split("/");
    newAddress.pop();
    newAddress.pop();
    const newAddressString = newAddress.join("/") + "/";
    setRootAddress(newAddressString);

    localStorage.setItem("defaultRootAddressModspaceImport", newAddressString);
    handleLoadModspaceFolder(newAddressString);
  };

  const fetchModspaceData = () => {
    localStorage.setItem("defaultRootAddressModspaceImport", rootAddress);
    if (
      modspacePassword.length > MAXIMUM_CLEAR_PASSWORD &&
      modspacePassword.endsWith("=")
    ) {
      handleLoadModspaceFolder(rootAddress);
    } else {
      //Password is not encrypted, so we encrypt it
      const newPassword = encryptStringWithRsaPublicKey(modspacePassword);
      setModspacePassword(newPassword);
      localStorage.setItem("modspaceUsername", modspaceUsername);
      localStorage.setItem("modspacePassword", newPassword);
      handleLoadModspaceFolder(rootAddress, newPassword);
    }
  };

  const handleSelectMultipleRows = (e: any) => {
    setSelectedRows(e);
  };

  const {
    importContentMutation,
    modspaceImportMutationData,
    isLoadingUploadMutation,
    errorUploadMutation
  } = useImportContentFromModspaceMutation();

  const closeModal = () => {
    setIsImportModalVisible(false);
    setQualityCheckStatus(null);
    setSelectedRows([]);
    setDescription("");
  };

  function changeQualityCheckedStatus(
    event: React.ChangeEvent<HTMLSelectElement>
  ) {
    event.preventDefault();
    setQualityCheckStatus(
      QualityCheckStatus[event.target.value as QualityCheckStatusType]
    );
  }

  const importAll = () => {
    if (
      weHaveSameNameSomewhere &&
      !window.confirm("Are you sure you want to overwrite existing files?")
    ) {
      return;
    }

    importContentMutation({
      variables: {
        modspaceUser: modspaceUsername,
        encryptedPassword: modspacePassword,
        modspaceParentFolder: rootAddress,
        absoluteModspacePaths: selectedRows,
        repoParentFolder: props.currentFolder,
        activityId: activityId,
        qualityCheckStatus:
          getQualityCheckStatusKeyFromValue(qualityCheckStatus),
        description: description
      }
    }).then(() => {
      if (!error) {
        closeModal();
        props.refetchRepoObjects();
      }
    });
  };

  return (
    <>
      <NavDropdown.Item
        size="small"
        variant="outlined"
        onClick={() => {
          setIsImportModalVisible(true);
        }}
        disabled={props.disabled}
        id="importFromModspaceItem"
      >
        <AddToHomeScreenIcon /> Modspace Import
      </NavDropdown.Item>

      {modspaceImportMutationData?.importModspaceContent?.actionStatus ===
        "Pending" && (
        <Success message="Modspace import started. It can take up to 15 Minutes." />
      )}
      {(error ||
        errorUploadMutation ||
        modspaceImportMutationData?.importModspaceContent?.actionStatus ===
          "Error") && <Error error={error || errorUploadMutation} />}
      <Modal
        show={isImportModalVisible}
        onHide={closeModal}
        enforceFocus={false}
        dialogClassName="modal-80w"
        aria-labelledby="importModscapeTitle"
      >
        <Modal.Header closeButton>
          <Modal.Title id="importFromModscapeTitle">
            Import from Modspace into &quot;{activityData.trialNumber}/
            {props.currentFolder}&quot;
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row
              className={
                modspacePassword.length > MAXIMUM_CLEAR_PASSWORD
                  ? "login_grey_color_row"
                  : ""
              }
            >
              <Col xs={2}>
                <Form.Label>Modspace Username:</Form.Label>
              </Col>
              <Col xs={3}>
                <Form.Control
                  type="text"
                  required
                  placeholder="Enter username"
                  id="importFromModspaceUserFormControl"
                  value={modspaceUsername}
                  onChange={(e) => setModspaceUsername(e.target.value)}
                />
              </Col>
            </Row>
            <Row
              className={
                modspacePassword.length > MAXIMUM_CLEAR_PASSWORD
                  ? "login_grey_color_row"
                  : ""
              }
            >
              <Col xs={2}>
                <Form.Label>Modspace Password:</Form.Label>
              </Col>
              <Col xs={3}>
                <Form.Control
                  type="password"
                  required
                  placeholder="Enter password"
                  id="importFromModspacePasswordFormControl"
                  value={modspacePassword}
                  onChange={(e) => setModspacePassword(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={2}>
                <Form.Label>Modspace folder:</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  required
                  placeholder="Enter ModSpace folder"
                  id="importFromModspaceRootAddressFormControl"
                  value={rootAddress}
                  onChange={(e) => setRootAddress(e.target.value)}
                />
              </Col>
              <Col xs={2}>
                <Button
                  variant="primary"
                  id="getModspaceContentButton"
                  disabled={
                    !rootAddress.endsWith("/") ||
                    !modspaceUsername ||
                    !modspacePassword
                  }
                  onClick={fetchModspaceData}
                >
                  {rootAddress.endsWith("/") ? "Open folder" : "Not a folder"}
                </Button>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col xs={2}>
                <Form.Label>Import Description:</Form.Label>
              </Col>
              <Col xs={3}>
                <Form.Control
                  type="text"
                  required
                  placeholder="Enter description"
                  id="importFromModspaceDescriptionFormControl"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={2}>
                <Form.Label>Quality Controlled Status</Form.Label>
              </Col>
              <Col xs={3}>
                <Form.Select
                  value={String(
                    getQualityCheckStatusKeyFromValue(qualityCheckStatus)
                  )}
                  onChange={changeQualityCheckedStatus}
                  key="modspace_quality_controlled"
                  id="importFromModspaceQualityControlledFormSelect"
                >
                  <option key={null}>None</option>
                  {Object.entries(QualityCheckStatus).map((element) => (
                    <option key={element[0]} value={element[0]}>
                      {element[1]}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
            <br />
            <br />

            {loading || isLoadingUploadMutation ? (
              <div className="center_div">
                <Spinner animation="border" className="spinner_color" />
                <p>Loading...</p>
              </div>
            ) : (
              data && (
                <>
                  <Row>
                    <Col xs={2}>
                      <Button variant="light" onClick={clickOnBack}>
                        <ArrowBackIcon className="w-auto align-self-center" />{" "}
                        Back
                      </Button>
                    </Col>
                    <Col xs={3}>
                      <Button
                        variant="primary"
                        disabled={selectedRows.length === 0}
                        onClick={importAll}
                        id="finishImportFromModspaceButton"
                      >
                        Import Selected File
                        {selectedRows.length > 1 &&
                          "s (" + selectedRows.length + ")"}
                      </Button>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <DataGridPro
                      rows={
                        data?.getModspaceContent
                          ? (data?.getModspaceContent.map(
                              (modspaceFile: ModspaceFile) => ({
                                id: rootAddress + modspaceFile.name,
                                name: modspaceFile.name.endsWith("/")
                                  ? modspaceFile.name.split("/").reverse()[1]
                                  : modspaceFile.name.split("/").reverse()[0],
                                isDir: modspaceFile.name.endsWith("/"),
                                size: modspaceFile.size
                              })
                            ) as readonly GridRowModel[])
                          : []
                      }
                      columns={columns}
                      pageSize={50}
                      rowHeight={60}
                      autoHeight
                      disableSelectionOnClick
                      checkboxSelection
                      className="browser_repository_table"
                      selectionModel={selectedRows}
                      onSelectionModelChange={handleSelectMultipleRows}
                    />
                  </Row>
                </>
              )
            )}
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}
