import { gql, useMutation } from "@apollo/client";

export const MUTATION_IMPORT_FILES = gql`
  mutation import_files($environmentId: ID!, $paths: [String!]) {
    importFiles(input: { environmentId: $environmentId, paths: $paths })
  }
`;

export function useImportFilesMutation() {
  const [
    importFiles,
    {
      data: idImportFiles,
      loading: isLoadingImportFiles,
      error: errorImportFiles
    }
  ] = useMutation(MUTATION_IMPORT_FILES, {
    context: { clientName: "interactive" }
  });

  return {
    importFiles,
    idImportFiles,
    isLoadingImportFiles,
    errorImportFiles
  };
}
