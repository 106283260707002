import CloudDoneOutlinedIcon from "@mui/icons-material/CloudDoneOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FileIcon from "../abstractComponents/fileIcon";
import MaterialButton from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import ScheduleSendOutlinedIcon from "@mui/icons-material/ScheduleSendOutlined";
import {
  DataGridPro,
  GridColDef,
  GridValueGetterParams
} from "@mui/x-data-grid-pro";
import { useContext, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip
} from "react-bootstrap";
import { useConnectEnvironmentMutation } from "../../backend/hooks/interactive/mutationConnectEnvironment";
import { useCreateEnvironmentMutation } from "../../backend/hooks/interactive/mutationCreateEnvironment";
import { useUpdateEnvironmentSettingsMutation } from "../../backend/hooks/interactive/mutationUpdateEnvironmentSettings";
import { useUpdateEnvironmentStatusMutation } from "../../backend/hooks/interactive/mutationUpdateEnvironmentStatus";
import { useQueryEnvironmentsQuery } from "../../backend/hooks/interactive/queryListEnvironments";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import RefreshIcon from "@mui/icons-material/Refresh";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useListImagesQuery } from "../../backend/hooks/interactive/queryListImages";
import { useListInstanceTypesQuery } from "../../backend/hooks/interactive/queryListInstanceTypes";
import {
  Environment,
  Image,
  InstanceType,
  Software,
  EnvironmentStatus,
  EnvironmentState
} from "../../backend/interactiveTypes";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";
import { BrowsePmxActivityContext } from "../../contexts/browsePmxActivityProvider";
import {
  formatStringToDateTime,
  priceCalculationPerHour,
  INTERACTIVE_ALERT_WARNING
} from "../../helpers/stringHelper";
import { useUserHelper } from "../../hooks/userHelper";
import Error from "../abstractComponents/error";
import ErrorBoundary from "../abstractComponents/errorBoundary";
import NavBarPmxActivity from "../pmxActivity/navbarPmxActivity";
import Success from "../abstractComponents/success";
import { useAuth } from "../authContextProvider";
import { InteractivePartialImport } from "./interactivePartialImport";
import UserManualLink from "../abstractComponents/usermanual";
import { useHistory } from "react-router-dom";

// Component used for creating and deleting of interactive environments
// and for transfering data and receiving informations concerning the interacitve environment
export function InteractiveList() {
  const { token } = useAuth();
  const { activityId, activityData, isActivityLoading, errorActivity } =
    useContext(PmxActivityDetailsContext);
  const { repos } = useContext(BrowsePmxActivityContext);
  const [currentSubArray, setCurrentSubarray] = useState<any[]>([]);
  const { isBayerUser } = useUserHelper();
  const history = useHistory();

  const COLUMNS = [
    {
      field: "environmentName",
      headerName: "Environment Name",
      flex: 2,
      renderCell: (params: any) => (
        <div key={params.row.environmentId} className="full_100_width">
          <div className="button_without_text_transform float-start">
            <Link
              className="interactive_link"
              to={`/activity/${activityId}/interactive/${params.row.environmentId}`}
            >
              <CloudDoneOutlinedIcon /> {params.row.environmentName}
            </Link>
          </div>
        </div>
      )
    },
    {
      field: "image",
      headerName: "Image",
      flex: 1,
      renderCell: (params: any) => (
        <>
          {params.row.image.imageName}{" "}
          {params.row.image.software &&
            params.row.image.software.length > 0 && (
              <OverlayTrigger
                placement="right"
                overlay={(props) => (
                  <Tooltip {...props} id="info-image-tooltip">
                    {params.row.image.software.map((software: Software) => (
                      <>
                        {software.name} - {software.version} <br />
                      </>
                    ))}
                  </Tooltip>
                )}
              >
                <Badge className="ineractive_info_badge" bg="info">
                  Info
                </Badge>
              </OverlayTrigger>
            )}
        </>
      )
    },
    {
      field: "state",
      headerName: "State",
      flex: 1,
      renderCell: (params: any) => {
        let displayState = params.row.state;

        if (
          params.row.state === EnvironmentState.Running &&
          (params.row.instanceStatus !== EnvironmentStatus.Ok ||
            params.row.systemStatus !== EnvironmentStatus.Ok)
        ) {
          displayState = EnvironmentState.Pending;
        }

        if (
          params.row.instanceStatus === EnvironmentStatus.InsufficientData ||
          params.row.instanceStatus === EnvironmentStatus.Impaired ||
          params.row.systemStatus === EnvironmentStatus.InsufficientData ||
          params.row.systemStatus === EnvironmentStatus.Impaired
        ) {
          displayState = "error";
        }

        return (
          <div
            key={params.row.environmentId + "status"}
            className="full_100_width"
          >
            <div
              className="float-start center_span_table"
              title={`instanceStatus:${params.row.instanceStatus}, systemStatus:${params.row.systemStatus}`}
            >
              {displayState}
            </div>
            {(params.row.state === EnvironmentState.Running ||
              params.row.state === EnvironmentState.Stopped) && (
              <MaterialButton
                variant="text"
                className="float-end inner_menu_button"
                onClick={(e) => handleClickStatus(e, params.row)}
              >
                <MoreVertIcon className="dot_menu_change_status grey_color" />
              </MaterialButton>
            )}
          </div>
        );
      }
    },
    {
      field: "instanceType",
      headerName: "Instance Type",
      flex: 1,
      renderCell: (params: any) => (
        <div
          key={params.row.environmentId + "instanceType"}
          className="full_100_width"
          title="Server must be stopped for this to change"
        >
          <div className="float-start center_span_table">
            {params.row.instanceType.instanceTypeId}
          </div>
          {(params.row.state === EnvironmentState.Running ||
            params.row.state === EnvironmentState.Stopped) && (
            <MaterialButton
              variant="text"
              className="float-end inner_menu_button"
              onClick={(e) => handleClickInstanceType(e, params.row)}
              disabled={params.row.state !== EnvironmentState.Stopped}
            >
              <MoreVertIcon className="dot_menu_change_status grey_color" />
            </MaterialButton>
          )}
        </div>
      )
    },
    {
      field: "privateIP", // reuse another column
      flex: 1,
      headerName: "Price",
      valueGetter: (params: GridValueGetterParams) =>
        `€${Math.round(
          priceCalculationPerHour(params.row.instanceType.instanceTypeId) * 24
        )}/Day`
    },
    {
      field: "instanceId", // reuse another column
      headerName: "Connection",
      flex: 1.2,
      renderCell: (params: any) => (
        <div className="d-flex gap-2 justify-content-end">
          <Button
            key={params.row.environmentId + "Connection"}
            onClick={() => {
              connectToEnvironment(params.row.environmentId);
            }}
            size="sm"
            className="connect_to_interactive"
            disabled={
              params.row.instanceStatus !== EnvironmentStatus.Ok ||
              params.row.systemStatus !== EnvironmentStatus.Ok ||
              params.row.state !== EnvironmentState.Running
            }
          >
            Connect
          </Button>
          <Button
            id="run-execution-job-button"
            size="sm"
            onClick={() => {
              history.push(
                `/activity/${activityData.id}/interactive/${params.row.environmentId}/IEPlugin`
              );
            }}
            hidden={
              params.row.instanceStatus !== EnvironmentStatus.Ok ||
              params.row.systemStatus !== EnvironmentStatus.Ok ||
              params.row.state !== EnvironmentState.Running
            }
          >
            Run Job
          </Button>
        </div>
      )
    },
    { field: "createdBy", flex: 1, headerName: "Created By" },
    {
      field: "createdAt",
      flex: 1,
      headerName: "Created At",
      valueGetter: (params: GridValueGetterParams) =>
        formatStringToDateTime(params.row.createdAt)
    }
  ];

  const import_columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Path",
      flex: 4,
      renderCell: (params: any) => (
        <>
          <FileIcon
            fileName={params.row.name}
            isValid={params.row.isValid}
            fontSize={"large"}
          />
          {params.row.name}
        </>
      )
    },
    {
      field: "createdAt",
      flex: 1,
      headerName: "Created At",
      valueGetter: (params: GridValueGetterParams) =>
        formatStringToDateTime(params.row.createdAt)
    },
    {
      field: "createdBy",
      flex: 1,
      headerName: "Created By",
      renderCell: (params: any) => params.row.createdBy
    }
  ];

  const [isCreateWindowOpen, setIsCreateWindowOpen] = useState(false);
  const [interactiveName, setInteractiveName] = useState("");
  const [currentEnvironment, setCurrentEnvironment] =
    useState<null | Environment>(null);
  const [newImageId, setNewImageId] = useState("");
  const [newSizeId, setNewSizeId] = useState("");

  const [anchorElementStatus, setAnchorElementStatus] =
    useState<null | HTMLElement>(null);

  const [anchorElementInstanceType, setAnchorElementInstanceType] =
    useState<null | HTMLElement>(null);
  const [isImportAllFiles, setIsImportAllFiles] = useState<boolean>(true);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedFileImport, setSelectedFileImport] = useState<string[]>([]);
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(0);
  const {
    environments,
    isLoadingEnvironments,
    errorListEnvironments,
    refetchEnvironments
  } = useQueryEnvironmentsQuery(activityId);

  const { connectEnvironment, isLoadingConnectionUrl, errorConnectionUrl } =
    useConnectEnvironmentMutation();

  const {
    updateEnvironmentStatus,
    isLoadingUpdateEnvironmentStatus,
    errorLoadingUpdateEnvironmentStatus,
    updateEnvironmentStatusData
  } = useUpdateEnvironmentStatusMutation();

  const {
    updateEnvironmentSettings,
    isLoadingEnvironmentSettings,
    errorEnvironmentSettings,
    updateEnvironmentSettingsData
  } = useUpdateEnvironmentSettingsMutation();

  const {
    environmentTypes,
    isLoadingEnvironmentTypes,
    errorListEnvironmentTypes
  } = useListInstanceTypesQuery();

  const {
    createEnvironment,
    isLoadingEnvironmentCreated,
    errorEnvironmentCreated,
    environmentCreated
  } = useCreateEnvironmentMutation();

  const { images, isLoadingListImages, errorListImages } = useListImagesQuery();

  const saveNewInteractive = () => {
    createEnvironment({
      variables: {
        activityId: activityId,
        imageId: newImageId,
        instanceTypeId: newSizeId,
        environmentName: interactiveName,
        paths: isImportAllFiles ? [] : decorateFiles()
      }
    }).then(() => {
      refetchEnvironments();
      setIsCreateWindowOpen(false);
      setNewImageId("");
      setNewSizeId("");
      setInteractiveName("");
      setActiveStep(0);
      setSelectedFileImport([]);
      setIsImportAllFiles(true);
    });
  };

  const connectToEnvironment = (environmentId: String) => {
    connectEnvironment({ variables: { environmentId: environmentId } }).then(
      ({ data }) => {
        window
          .open(
            data.connectEnvironment +
              "?id_token=" +
              encodeURIComponent(token!) +
              "&environment_id=" +
              environmentId,
            "_blank"
          )
          ?.focus();
      }
    );
  };

  const handleClickStatus = (
    event: React.MouseEvent<HTMLButtonElement>,
    environment: Environment
  ) => {
    setAnchorElementStatus(event.currentTarget);
    setCurrentEnvironment(environment);
  };

  const handleClickInstanceType = (
    event: React.MouseEvent<HTMLButtonElement>,
    environment: Environment
  ) => {
    setAnchorElementInstanceType(event.currentTarget);
    setCurrentEnvironment(environment);
  };

  const updateEnvStatus = (state: string) => {
    setAnchorElementStatus(null);
    if (
      state !== "delete" ||
      (state === "delete" && window.confirm(INTERACTIVE_ALERT_WARNING))
    ) {
      updateEnvironmentStatus({
        variables: {
          environmentId: currentEnvironment?.environmentId,
          environmentStatus: state
        }
      }).then(() => {
        refetchEnvironments();
      });
    }
  };

  const updateEnvType = (type: string) => {
    setAnchorElementInstanceType(null);
    updateEnvironmentSettings({
      variables: {
        environmentId: currentEnvironment?.environmentId,
        instanceTypeId: type
      }
    }).then(() => {
      refetchEnvironments();
    });
  };

  // modal steps
  const steps = [
    "Interactive Environment Settings",
    "Choose files to import",
    "Summary"
  ];

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const handleNext = () => {
    if (isImportAllFiles) {
      setActiveStep((prevActiveStep) => prevActiveStep + 2);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (isImportAllFiles) {
      setActiveStep((prevActiveStep) => prevActiveStep - 2);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const setSelectedImport = () => {
    setSelectedFileImport([]);
    setIsImportAllFiles((prevState) => !prevState);
  };

  const getFileColumns = () => {
    let rows: any = [];
    selectedFileImport.forEach((x) => {
      const finding = currentSubArray.find((y) => y.name === x);
      if (finding) {
        rows.push(finding);
      }
    });

    return rows;
  };

  useEffect(() => {
    const currentArray: any[] = [];
    repos.map((repo: any) =>
      repo.repoObjects.map((file: any) =>
        currentArray.push({
          repoName: repo.repo.name,
          name: `${repo.repo.name}/${file.name}`,
          isDir: file.isDir,
          createdAt: file.created,
          createdBy: file.createdBy,
          isValid: file.isValid
        })
      )
    );

    setCurrentSubarray(currentArray);
  }, [repos]);

  useEffect(() => {
    if (isImportAllFiles) {
      const rows: any = [];
      currentSubArray.map((file) => rows.push(file.name));

      setSelectedFileImport(rows);
    }
  }, [isImportAllFiles, currentSubArray]);

  const findInstance = (newSizeId: string) => {
    return environmentTypes?.listInstanceTypes.find(
      (s: any) => s.instanceTypeId === newSizeId
    );
  };

  const decorateFiles = () => {
    const decoratedFiles: string[] = [];
    currentSubArray?.forEach((x) => {
      if (
        selectedFileImport.length > 0 &&
        selectedFileImport.includes(x.name)
      ) {
        decoratedFiles.push(x.name);
      }
    });

    return decoratedFiles;
  };

  return (
    <>
      <NavBarPmxActivity activeIndex={1} />
      <div className="app_content pmx_app_content">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          className="float-start pmx_bradcrumbs"
        >
          <Link to={`/activity/${activityId}`}>
            {activityData?.trialNumber}
          </Link>
          ,<Typography>Interactive Environments</Typography>
        </Breadcrumbs>
        <br />
        <hr />
        {isBayerUser && activityData && (
          <ErrorBoundary>
            <Container fluid>
              <Row>
                <Col xs={7}>
                  {(isLoadingEnvironments ||
                    isLoadingUpdateEnvironmentStatus ||
                    isLoadingEnvironmentTypes ||
                    isActivityLoading ||
                    isLoadingEnvironmentSettings ||
                    isLoadingConnectionUrl ||
                    isLoadingListImages ||
                    isLoadingEnvironmentCreated) && (
                    <div className="center_div">
                      <Spinner animation="border" className="spinner_color" />
                      <p>Loading...</p>
                    </div>
                  )}
                </Col>
                <Col>
                  <Button
                    onClick={() => refetchEnvironments()}
                    id="refresh_list_of_env"
                    variant="outline-secondary"
                    className="float-end"
                  >
                    <RefreshIcon /> Refresh
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={() => setIsCreateWindowOpen(true)}
                    id="create_new_interactive"
                    className="float-end button-secondary"
                  >
                    <CloudDoneOutlinedIcon /> Create New Interactive
                  </Button>
                </Col>
              </Row>
              <br />
              <Row>
                <DataGridPro
                  rows={environments ? environments?.listEnvironments : []}
                  columns={COLUMNS}
                  rowHeight={60}
                  pageSize={10}
                  getRowId={(row) => row.environmentId}
                  autoHeight
                  disableSelectionOnClick
                  className="choose_interactive_list"
                />
              </Row>

              <Modal
                show={isCreateWindowOpen}
                onHide={() => {
                  setIsCreateWindowOpen(false);
                  setActiveStep(0);
                }}
                dialogClassName="modal-75w"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Create New Interactive</Modal.Title>
                  <UserManualLink
                    url="/usermanual/interactive/"
                    testId="um-interactive-create"
                    className="align-self-start ms-1"
                  />
                </Modal.Header>
                <Modal.Body>
                  {isLoadingEnvironmentCreated && (
                    <div className="center_div">
                      <Spinner animation="border" className="spinner_color" />
                      <p>Loading...</p>
                    </div>
                  )}
                  <Stepper activeStep={activeStep} className="mb-4">
                    {steps.map((label, index) => {
                      const stepProps: { completed?: boolean } = {};
                      const labelProps: {
                        optional?: React.ReactNode;
                      } = {};
                      if (isStepOptional(index)) {
                        labelProps.optional = (
                          <Typography variant="caption">Optional</Typography>
                        );
                      }
                      return (
                        <Step key={label} {...stepProps}>
                          <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                  {activeStep === 0 && (
                    <>
                      <Form.Group
                        className="mb-3"
                        key="form_interactive_name"
                        controlId="interactive_name"
                      >
                        <Form.Label>
                          Interactive Name <span className="red_form">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          required
                          placeholder="Interactive Name"
                          value={interactiveName}
                          onChange={(e) => setInteractiveName(e.target.value)}
                        />
                      </Form.Group>
                      <br />
                      <FormControl
                        fullWidth
                        id="interactive-modal-form-image-type"
                      >
                        <InputLabel id="interactive-modal-input-image-type">
                          Image
                        </InputLabel>

                        <Select
                          labelId="interactive-modal-input-image-type"
                          id="interactive-modal-select-image-type"
                          data-testid="image-type"
                          value={newImageId}
                          label="Image"
                          onChange={(event) => {
                            setNewImageId(event.target.value);
                          }}
                        >
                          {images?.listImages.map((envType: Image) => (
                            <MenuItem
                              value={envType.imageId}
                              key={envType.imageId}
                            >
                              {envType.imageName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <br />
                      <br />
                      <FormControl
                        fullWidth
                        id="interactive-modal-form-instance-type"
                      >
                        <InputLabel id="interactive-modal-input-instance-type">
                          Instance Type
                        </InputLabel>

                        <Select
                          labelId="interactive-modal-input-instance-type"
                          id="interactive-modal-select-instance-type"
                          data-testid="instance-type"
                          value={newSizeId}
                          label="Select a Size"
                          onChange={(event) => {
                            setNewSizeId(event.target.value);
                          }}
                        >
                          {environmentTypes?.listInstanceTypes.map(
                            (envType: InstanceType) => (
                              <MenuItem
                                key={envType.instanceTypeId}
                                value={envType.instanceTypeId}
                                id={`instance-type-${envType.instanceTypeId}`}
                              >
                                <Row
                                  id={`row-instance-type-${envType.instanceTypeId}`}
                                >
                                  <Typography
                                    sx={{ width: "200px", fontWeight: "bold" }}
                                    variant="button"
                                    display="block"
                                  >
                                    {envType.instanceTypeId}
                                  </Typography>
                                  <Typography sx={{ width: "140px" }}>
                                    {envType.cpu}xCPU
                                  </Typography>
                                  <Typography sx={{ width: "150px" }}>
                                    {envType.memory}GB RAM
                                  </Typography>
                                  <Typography sx={{ width: "140px" }}>
                                    €
                                    {Math.round(
                                      priceCalculationPerHour(
                                        envType.instanceTypeId
                                      ) * 24
                                    )}
                                    /Day
                                  </Typography>
                                </Row>
                              </MenuItem>
                            )
                          )}
                        </Select>
                        <div className="mx-2">
                          <FormControlLabel
                            control={<Checkbox checked={isImportAllFiles} />}
                            onChange={setSelectedImport}
                            label="Import All Files"
                          />
                        </div>
                      </FormControl>
                    </>
                  )}
                  {activeStep === 1 && (
                    <>
                      <InteractivePartialImport
                        selectedFileImport={selectedFileImport}
                        setSelectedFileImport={setSelectedFileImport}
                      />
                    </>
                  )}

                  {activeStep === 2 && (
                    <Form.Group>
                      <Form.Label className="mt-2">Interactive Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={interactiveName}
                        disabled
                      />
                      <Form.Label className="mt-2">Image</Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          images.listImages.find(
                            (i: any) => i.imageId === newImageId
                          )?.imageName
                        }
                        disabled
                      />
                      <Form.Label className="mt-2">Instance Type</Form.Label>
                      <br />
                      <div className="d-flex gap-4">
                        {findInstance(newSizeId)?.instanceTypeId}
                        <div>{findInstance(newSizeId)?.cpu}xCPU</div>
                        <div> {findInstance(newSizeId)?.memory}GB RAM</div>
                        <div>
                          €
                          {Math.round(
                            priceCalculationPerHour(
                              findInstance(newSizeId)?.instanceTypeId
                            ) * 24
                          )}
                          /Day
                        </div>
                      </div>
                      <FormControlLabel
                        control={<Checkbox checked={isImportAllFiles} />}
                        disabled
                        label="Import All Files"
                      />
                      <br />
                      <DataGridPro
                        componentsProps={{ panel: { disablePortal: true } }}
                        rows={
                          selectedFileImport.length > 0
                            ? getFileColumns()
                            : currentSubArray
                        }
                        columns={import_columns}
                        getRowId={(row) => row.name}
                        rowHeight={50}
                        initialState={{
                          sorting: {
                            sortModel: [{ field: "name", sort: "asc" }]
                          }
                        }}
                        pagination
                        pageSize={pageSize}
                        page={page}
                        onPageChange={(newPage) => setPage(newPage)}
                        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
                        rowsPerPageOptions={[50, 100, 500, 1000]}
                        className="interactive-file-summary-table"
                      />
                    </Form.Group>
                  )}
                  <br />
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    className="me-auto"
                    variant="secondary"
                    onClick={() => {
                      setIsCreateWindowOpen(false);
                      setActiveStep(0);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    Back
                  </Button>
                  <Button
                    variant="primary"
                    disabled={
                      isLoadingEnvironmentCreated ||
                      interactiveName === "" ||
                      newImageId === "" ||
                      newSizeId === "" ||
                      (activeStep === 1 &&
                        !isImportAllFiles &&
                        selectedFileImport.length === 0)
                    }
                    onClick={activeStep === 2 ? saveNewInteractive : handleNext}
                  >
                    {activeStep === 2 ? "Create New" : "Next"}
                  </Button>
                </Modal.Footer>
              </Modal>
              <br />
            </Container>

            <Menu
              id="simple-menu-status"
              anchorEl={anchorElementStatus}
              open={Boolean(anchorElementStatus)}
              onClose={() => {
                setAnchorElementStatus(null);
              }}
            >
              <MenuItem
                id="start_status"
                onClick={() => {
                  updateEnvStatus("start");
                }}
                disabled={
                  currentEnvironment?.state === EnvironmentState.Running
                }
              >
                Start
              </MenuItem>
              <MenuItem
                id="stop_status"
                onClick={() => {
                  updateEnvStatus("stop");
                }}
                disabled={
                  currentEnvironment?.state === EnvironmentState.Stopped
                }
              >
                Stop
              </MenuItem>
              <MenuItem
                id="delete_status"
                onClick={() => {
                  updateEnvStatus("delete");
                }}
              >
                Delete
              </MenuItem>
            </Menu>

            <Menu
              id="simple-menu-status"
              anchorEl={anchorElementInstanceType}
              open={Boolean(anchorElementInstanceType)}
              onClose={() => {
                setAnchorElementInstanceType(null);
              }}
            >
              {environmentTypes?.listInstanceTypes.map(
                (instanceType: InstanceType) => (
                  <MenuItem
                    key={instanceType.instanceTypeId}
                    id={"instance_type_" + instanceType.instanceTypeId}
                    onClick={() => {
                      updateEnvType(instanceType.instanceTypeId);
                    }}
                  >
                    <Typography
                      sx={{ width: "200px", fontWeight: "bold" }}
                      variant="button"
                      display="block"
                    >
                      {instanceType.instanceTypeId}
                    </Typography>
                    <Typography sx={{ width: "140px" }}>
                      {instanceType.cpu}xCPU
                    </Typography>
                    <Typography sx={{ width: "150px" }}>
                      {instanceType.memory}GB RAM
                    </Typography>
                    <Typography sx={{ width: "140px" }}>
                      €
                      {Math.round(
                        priceCalculationPerHour(instanceType.instanceTypeId) *
                          24
                      )}
                      /Day
                    </Typography>
                  </MenuItem>
                )
              )}
            </Menu>

            {(errorListEnvironments ||
              errorLoadingUpdateEnvironmentStatus ||
              errorListEnvironmentTypes ||
              errorActivity ||
              errorEnvironmentSettings ||
              errorConnectionUrl ||
              errorListImages ||
              errorEnvironmentCreated) && (
              <Error
                error={
                  errorListEnvironments ||
                  errorLoadingUpdateEnvironmentStatus ||
                  errorListEnvironmentTypes ||
                  errorActivity ||
                  errorEnvironmentSettings ||
                  errorConnectionUrl ||
                  errorListImages ||
                  errorEnvironmentCreated
                }
              />
            )}

            {(updateEnvironmentStatusData || updateEnvironmentSettingsData) && (
              <Success message="The update will take up to one minute" />
            )}
            {environmentCreated && (
              <Success message="The initialization will take some minutes, please wait until state is running and connect button is visible and active." />
            )}
          </ErrorBoundary>
        )}
      </div>
    </>
  );
}

export default InteractiveList;
