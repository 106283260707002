import {
  Button,
  Form,
  Modal,
  Spinner,
  InputGroup,
  Row,
  Col
} from "react-bootstrap";
import { useEffect, useState } from "react";
import { useRenameObjectsMutation } from "../../../backend/hooks/contextMenu/fileOperations/mutationRenameObjects";
import Error from "../../abstractComponents/error";
import { RepoObject } from "../../../helpers/treeHelper";

export const OVERRIDE_MESSAGE = "has been hidden at destination.";

// Component used as a popup when an error comes from GraphQL
export default function RenameModal(props: {
  activityId: number;
  tableMenuWasOpenOnPath: string;
  selectedRows: any[];
  isRenameModalOpen: boolean;
  setIsRenameModalOpen: Function;
  refetchRepoObjects: Function;
  tree: any[];
  currentSelectedPath: string;
}) {
  const [renameNewName, setRenameNewName] = useState("");
  const [renameInputError, setRenameInputError] = useState("");
  const [renameComment, setRenameComment] = useState("");
  const [allObjects, setAllObjects] = useState<RepoObject[]>();

  function flatten_tree(tree: RepoObject[]): RepoObject[] {
    const res: RepoObject[] = [];
    for (const item of tree) {
      res.push(item);
      if (item.content) {
        res.push(...flatten_tree(item.content as RepoObject[]));
      }
    }

    return res;
  }

  useEffect(() => {
    setAllObjects(flatten_tree(props.tree));
  }, [props.tree]);

  useEffect(() => {
    let currentName = props.tableMenuWasOpenOnPath.toString();

    currentName = currentName.endsWith("/")
      ? currentName.slice(0, -1)
      : currentName;

    currentName = currentName.split("/").pop() || "";

    setRenameNewName(currentName);
  }, [props.tableMenuWasOpenOnPath]);

  const closeRenameModal = () => {
    props.setIsRenameModalOpen(false);
    setRenameNewName("");
    setRenameInputError("");
    setRenameComment("");
  };

  const { renameObjectsMutation, isRenameLoading, renameError } =
    useRenameObjectsMutation();

  function renameObjects() {
    renameObjectsMutation({
      variables: {
        absolutePath: props.selectedRows[0]
          ? props.selectedRows[0]
          : props.tableMenuWasOpenOnPath,
        activityId: props.activityId,
        newName: renameNewName,
        comment: renameComment
      }
    })
      .then(() => {
        props.refetchRepoObjects();
        closeRenameModal();
      })
      .catch((error) => {
        if (error && error.message.includes(OVERRIDE_MESSAGE)) {
          if (window.confirm(error.message + " Do you want to proceed?")) {
            renameObjectsMutation({
              variables: {
                absolutePath: props.selectedRows[0]
                  ? props.selectedRows[0]
                  : props.tableMenuWasOpenOnPath,
                activityId: props.activityId,
                newName: renameNewName,
                override: true,
                comment: renameComment
              }
            }).then(() => {
              props.refetchRepoObjects();
              closeRenameModal();
            });
          }
        }
      });
  }

  const handleRename = (event: any) => {
    let error = "";

    // Find current obj
    const absolute_path = props.selectedRows[0]
      ? props.selectedRows[0]
      : props.tableMenuWasOpenOnPath;
    var source_obj = allObjects!.find(
      (repoObj) => repoObj.name === absolute_path
    );
    let isFolder = source_obj!.isDir;
    let old_key_end = source_obj!.name + (isFolder ? "/" : "");
    let old_path = source_obj!.name;
    let idx = old_path.lastIndexOf(old_key_end);
    let new_key_end = renameNewName + (isFolder ? "/" : "");
    let destination_path = old_path.substring(0, idx) + new_key_end;
    var new_exists = allObjects!.find(
      (repoObj) => repoObj.name === destination_path
    );
    if (renameNewName.indexOf("/") !== -1) {
      // Check if name contains /
      error = "Please avoid directories '/' in the new name.";
    } else if (renameNewName === source_obj!.name) {
      // Check if name differs from original name
      error = "Please, enter a new name different than the original name.";
    } else if (new_exists) {
      error = [
        "The",
        new_exists.isDir ? "folder " : "file",
        new_exists.name,
        "already exists. Please, choose another name."
      ].join(" ");
    }

    if (!error) {
      renameObjects();
    } else {
      event.preventDefault();
      event.stopPropagation();
      setRenameInputError(error);
    }
  };

  const handleRenameNewName = (e: string) => {
    setRenameNewName(e);
    if (!e) {
      setRenameInputError("");
    }
  };

  return (
    <>
      <Modal show={props.isRenameModalOpen} onHide={closeRenameModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Row>
              <Col xs={8}>Rename</Col>
              <Col xs={4}>
                {isRenameLoading && (
                  <Spinner animation="border" className="spinner_color" />
                )}
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>This will rename the following item: </p>
          <ul id="rename_list_file">
            {props.tableMenuWasOpenOnPath ? (
              <li>{props.tableMenuWasOpenOnPath}</li>
            ) : (
              props.selectedRows.map((row, index) => <li key={index}>{row}</li>)
            )}
          </ul>
          <Form noValidate>
            <Form.Group className="mb-2">
              <Form.Label>New Name</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  as="textarea"
                  required
                  data-testid="rename_new_name_form"
                  placeholder="Enter new name"
                  aria-describedby="inputGroupPrepend"
                  value={renameNewName}
                  onChange={(e) => handleRenameNewName(e.target.value)}
                  id="rename_new_name_form"
                  isInvalid={!!renameInputError}
                />
                <Form.Control.Feedback type="invalid">
                  {renameInputError}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Label>Rename Comment</Form.Label>
              <InputGroup>
                <Form.Control
                  as="textarea"
                  data-testid="rename_comment_form"
                  placeholder="Enter comment"
                  value={renameComment}
                  onChange={(e) => setRenameComment(e.target.value)}
                  id="rename_comment_form"
                />
              </InputGroup>
            </Form.Group>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeRenameModal}>
                Cancel
              </Button>
              <Button
                id="rename"
                data-testid="rename"
                variant="danger"
                disabled={!renameNewName}
                onClick={handleRename}
              >
                Rename
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      {renameError && !renameError.message.includes(OVERRIDE_MESSAGE) && (
        <Error error={renameError} />
      )}
    </>
  );
}
