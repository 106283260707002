import { gql, useMutation } from "@apollo/client";

export const ADD_ACTIVITY_MUTATION = gql`
  mutation createPmxActivity(
    $trialNumber: String!
    $bayNumber: String
    $projectId: String
    $trialShortDescription: String
    $isStandard: Boolean
    $readOnlyRepositories: [Int]
  ) {
    createPmxActivity(
      input: {
        trialNumber: $trialNumber
        bayNumber: $bayNumber
        trialShortDescription: $trialShortDescription
        projectId: $projectId
        isStandard: $isStandard
        readOnlyRepositories: $readOnlyRepositories
      }
    ) {
      id
    }
  }
`;

export function useAddActivityMutation() {
  const [addNewActivity, { loading: isLoadingMutation, error: errorMutation }] =
    useMutation(ADD_ACTIVITY_MUTATION);

  return {
    addNewActivity,
    isLoadingMutation,
    errorMutation
  };
}
