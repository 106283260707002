import { useState, useEffect, useContext } from "react";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import {
  renderTreeNodesInteractive,
  StyledTreeItem,
  getFlattenedTree
} from "../../helpers/treeHelper";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import { BrowsePmxActivityContext } from "../../contexts/browsePmxActivityProvider";

export function InteractivePartialImport(props: {
  setSelectedFileImport: Function;
  selectedFileImport: string[];
}) {
  const { treeHierarchyGlobal } = useContext(BrowsePmxActivityContext);
  const [flattenTree, setFlattenTree] = useState<any[]>([]);

  useEffect(() => {
    setFlattenTree(getFlattenedTree(treeHierarchyGlobal));
  }, [treeHierarchyGlobal]);

  return (
    <>
      {treeHierarchyGlobal?.map((repo: any) => (
        <SimpleTreeView
          key={`${repo.repo.name}-index`}
          slots={{
            expandIcon: AddBoxOutlinedIcon,
            collapseIcon: IndeterminateCheckBoxOutlinedIcon
          }}
          defaultExpandedItems={["/"]}
          className={`mt-2 tree-view`}
          disableSelection
        >
          <StyledTreeItem
            key={"/"}
            itemId={"/"}
            label={
              <>
                <CenterFocusStrongIcon className="grey_color" />{" "}
                {repo.repo.name}
              </>
            }
          >
            {renderTreeNodesInteractive(
              repo.repoObjects.sort(function (a: any, b: any) {
                if (a.isDir && !b.isDir) {
                  return -1;
                }
                if (b.isDir && !a.isDir) {
                  return 1;
                }

                return a.name.localeCompare(b.name);
              }),
              props.selectedFileImport,
              props.setSelectedFileImport,
              repo.repo.name,
              true,
              true,
              flattenTree
            )}
          </StyledTreeItem>
        </SimpleTreeView>
      ))}
    </>
  );
}
