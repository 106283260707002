import { gql, useMutation } from "@apollo/client";

export const MUTATION_IMPORT_SVN_CONTENT = gql`
  mutation importSvnContent(
    $svnUser: String!
    $encryptedPassword: String!
    $absoluteSvnPaths: [String]!
    $svnParentFolder: String!
    $repoParentFolder: String!
    $activityId: ID!
    $qualityCheckStatus: QualityCheckStatus
    $description: String
  ) {
    importSvnContent(
      input: {
        svnUser: $svnUser
        encryptedPassword: $encryptedPassword
        svnParentFolder: $svnParentFolder
        absoluteSvnPaths: $absoluteSvnPaths
        repoParentFolder: $repoParentFolder
        activityId: $activityId
        qualityCheckStatus: $qualityCheckStatus
        description: $description
      }
    ) {
      actionId
      actionStatus
      imported {
        name
      }
    }
  }
`;

export function useImportContentFromSvnMutation() {
  const [
    importContentMutation,
    {
      data: svnImportMutationData,
      loading: isLoadingSvnMutation,
      error: errorSvnMutation
    }
  ] = useMutation(MUTATION_IMPORT_SVN_CONTENT, {
    onError(err: any) {
      console.log(err);
    }
  });

  return {
    importContentMutation,
    svnImportMutationData,
    isLoadingSvnMutation,
    errorSvnMutation
  };
}
